import * as models from '..'; 

export interface AdjusterFeeListRequest extends models.RequestBase {
    insClaimNo: string; 
    insCompany: string; 
    invoiceNumber: string; 
    fieldAdjuster: string; 
    insuredName: string; 
    catCode: string; 
    invoiceDateFrom: string; 
    invoiceDateTo: string; 
    paymentDateFrom: string; 
    paymentDateTo: string; 
    isPayrolled: boolean; 
    isAdvance: boolean; 
    hasAdjusterBalance: boolean; 
    hasAdjusterPercent: boolean; 
    isCorrection: boolean; 
    sort: string;
}

export const defaultAdjusterFeeListRequest: AdjusterFeeListRequest = {
    pageNo: 1, 
    pageSize: 20, 
    insClaimNo: '', 
    insCompany: '',
    invoiceNumber: '', 
    fieldAdjuster: '',
    insuredName: '',
    catCode: '',
    invoiceDateFrom: '', 
    invoiceDateTo: '', 
    paymentDateFrom: '',
    paymentDateTo: '',
    isPayrolled: false,
    isAdvance: false, 
    hasAdjusterBalance: false, 
    hasAdjusterPercent: false, 
    isCorrection: false, 
    sort: '-invoiceDate',
}