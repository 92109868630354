import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models';
import * as actions from './actions';
import * as constants from './constants';

export interface DashboardStateShape {
    isPayrollDashboardLoading: boolean; 
    monthlyClaimsActivity: models.MonthlyClaimActivityResponse; 
    payrollDashbaord: models.PayrollDashboard; 
 }

export const dashboardDefaultState: DashboardStateShape = {
    isPayrollDashboardLoading: false, 
    monthlyClaimsActivity: models.defaultDashbardResponse, 
    payrollDashbaord: models.defaultPayrollDashboard, 
};

export interface State {    
    dashboards: DashboardStateShape;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    dashboards: (state = dashboardDefaultState, action) => {
        switch (action.type) {   
            case constants.REQUEST_MONTHLY_CLAIM_ACTIVITY: 
                return {
                    ...state,                    
                }
            case constants.RECEIVE_MONTHLY_CLAIM_ACTIVITY:
                return {
                    ...state, 
                    monthlyClaimsActivity: action.payload.response,
                }

            case constants.REQUEST_PAYROLL_DASHBOARD: 
                return {
                    ...state, 
                    isPayrollDashboardLoading: true, 
                }
            case constants.RECEIVE_PAYROLL_DASHBOARD: 
                return {
                    ...state, 
                    isPayrollDashboardLoading: false, 
                    payrollDashbaord: action.payload.response,
                }

            default: 
                return state; 
        }
    }
});
