import { request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const getOpenClaimsByCompany = async () => {
    const response = await request ({
        method: 'GET',
        url: `/claims/openClaimsByCompany`
    }); 
    return response; 
};

export const getMonthlyClaimActivity = async (monthsBack: number, months: number) => {
    var qStr = utils.helpers.encodeQueryString({monthsBack, months});

    const response = await request ({
        method: 'GET',
        url: `/claims/monthlyClaimActivity?${qStr}`, 
    }); 
    return response; 
}; 

export const getPayrolLDashboard = async () => {
    const response = await request ({
        method: 'GET',
        url: `/dashboards/payroll`
    }); 
    return response; 
};