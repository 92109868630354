import * as React from 'react';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import '../../../assets/styles/myDashboard.css';
import MetricCard from '../MetricCard';
import Top10AdjustersTYD from './Top10AdjustersYTD';
import PayrollByMonth from './PayrollByMonth';
import { dashboardActions } from 'store/dashboard';

interface Props extends PropsFromRedux {
}

interface State {
}

class PayrollDashboard extends React.PureComponent<Props, State> {
    public state = {
    }

    public componentDidMount = () => {        
        this.props.requestPayrollDashboard(); 
    }

    public render() {
        const { payrollDashboard } = this.props; 

        return (
            <>
                <Row>
                    <Col>        
                        <div className='metric-group'>
                            <MetricCard
                                title='YTD Active Adjusters'
                                value={payrollDashboard.ytdActiveAdjusters}                            
                            />                        
                            <MetricCard
                                title='YTD Payroll Amt'
                                value={payrollDashboard.ytdPayrollAmt}
                                valueType='money'
                            />                        
                            <MetricCard
                                title='YTD Advances'
                                value={payrollDashboard.ytdAdvancedAmt}
                                valueType='money'
                            />
                            <MetricCard
                                title='Awaiting Payment'
                                value={payrollDashboard.fundsAwaitingPayout}
                                valueType='money'
                            />
                        </div>
                        <PayrollByMonth
                            payrollByMonth={payrollDashboard.payrollByMonth}
                        />
                    </Col>
                    <Col style={{paddingTop: ".5em"}}>                        
                        <Top10AdjustersTYD 
                            payrollByAdjuster={payrollDashboard.topTenAdjusters}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    isPayrollDashboardLoading: state.dashboards.dashboards.isPayrollDashboardLoading,
    payrollDashboard: state.dashboards.dashboards.payrollDashbaord,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            requestPayrollDashboard: dashboardActions.requestPayrollDashboard, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PayrollDashboard); 
