import * as React from 'react';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Accordion, Button, Card, CardGroup, Col, Form, Modal, ProgressBar, Row, Spinner } from 'react-bootstrap'; 
import { CardBody } from 'reactstrap';
import * as models from '../../models'
import PayrollAdvanceModal from './PayrollAdvanceModal';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';
import AdjusterFeeTable from './AdjusterFeeTable';
import { companyActions } from 'store/companies';
import _ from 'lodash'; 
import iconCheck from '../../assets/images/icons8-ok.svg'; 
import { userHasPermission } from '../../utils/helpers';
import { payrollActions } from 'store/payroll';
import { userActions } from 'store/user'; 
import { AppToastTypes } from 'models/enums'; 
import { pollPayrollActions } from 'store/pollPayroll';

interface Props extends PropsFromRedux {
    showModal: boolean;
    closeModal: () => void;
}

interface State {       
    buildComplete: boolean; 
    buttonText: string;      
    closeNotify: boolean; 
    comment: string; 
    company: string; 
    inclegacy: boolean; 
    payrollFees: models.AdjusterFee[]; 
    payrollDateStart: string; 
    payrollDateTo: string;    
    payrollMode: string; 
    showAdvancedModal: boolean;    
    showProgressBar: boolean; 
}

interface feesByAdjuster {
    adjuster: string, 
    feeCount: number, 
    sum: number, 
    advSum: number, 
    fees: models.AdjusterFee[]
}

class PayrollDetail extends React.Component<Props, State> {
    public state = {        
        buildComplete: false, 
        buttonText: 'FINALIZE',  
        closeNotify: false, 
        comment: '', 
        company: '', 
        inclegacy: true, 
        payrollFees: new Array<models.AdjusterFee>,
        payrollDateStart: '',  
        payrollDateTo: '',     
        payrollMode: "init",
        showAdvancedModal: false, 
        showProgressBar: false, 
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if(!_.isEqual(prevState.payrollDateStart, this.state.payrollDateStart)) {            
            if (this.state.payrollDateTo != "")
                this.setState({payrollMode: "search"}); 
        }
        if(!_.isEqual(prevState.payrollDateTo, this.state.payrollDateTo)) {
            if (this.state.payrollDateStart != "")
                this.setState({payrollMode: "search"}); 
        }
        if(!_.isEqual(prevState.payrollFees, this.state.payrollFees) && this.state.payrollDateStart.length > 0) {
            this.setState({payrollMode: "ready"}); 
        }
        if(!_.isEqual(prevProps.postedPayrollId, this.props.postedPayrollId) && this.props.postedPayrollId != -1 && this.props.showModal){
            this.props.startPolling(this.props.postedPayrollId); 
        }  
        if(!_.isEqual(prevProps.showModal, this.props.showModal) && !this.props.showModal){
            this.props.requestPayrollList(this.props.payrollListRequest); 
            this.props.stopPolling(); 
        }                      
        if(!_.isEqual(prevProps.payrollStatus, this.props.payrollStatus) 
            && (this.props.payrollStatus.status.toLowerCase() === "completed"
            || this.props.payrollStatus.status.toLowerCase().includes("error"))

        ){
            this.setState({buildComplete: true, payrollMode: "completed", buttonText: this.props.payrollStatus.status}); 
            this.props.stopPolling(); 
            if(this.props.payrollStatus.status.toLowerCase() === "completed" && this.props.showModal)
                this.props.requestPayrollZipFile(this.props.postedPayrollId); 
        }       
        if(!_.isEqual(prevProps.payrollStatus, this.props.payrollStatus) 
            && prevProps.payrollStatus.status.toLowerCase().includes('building')
            && !this.props.payrollStatus.status.toLowerCase().includes('building')){
            this.setState({buildComplete: true}); 
        }
    }   
    
    public componentDidMount = () => {
        const request: models.AdjusterFeeRequest = {
            isPaid: false, 
            hasAdjusterAmt: true, 
        }        
        this.props.requestAdjusterFees(request);   
        this.props.requestInsCompanyDD();        
    }
    
    public submitForm = (e:any) => {                
        e.preventDefault();         
        const addPayroll: models.AddPayroll = {
            startDate: this.state.payrollDateStart,
            endDate: this.state.payrollDateTo,
            comments: this.state.comment,          
            addLegacy: this.state.inclegacy,  
            notifyUser: this.state.closeNotify, 
            payrollFees: this.state.payrollFees.map(i => ({id: i.adjusterFeeId, amt: i.adjusterBalance} as models.AddPayrollFee)),
        }             
        this.props.addPayroll(addPayroll);        
        if(this.state.closeNotify){            
            this.resetFormStateAndClose(); 
        }
        else {
            this.setState({buttonText: "RUNNING...", payrollMode: 'executing', showProgressBar: true});          
        }
    }

    public resetFormStateAndClose = () => {
        this.clearForm(); 
        this.props.closeModal(); 
    }

    public handleFilters =(e: any) => {
        const { name, value } = e.target;
        switch (name) {
            case 'comment':
                this.setState({comment: value, payrollMode: 'search'}); 
                break; 
            case 'insCompany':
                this.setState({company: value, payrollMode: 'search'}); 
                break; 
            case 'incLegacy':
                this.setState({inclegacy: !this.state.inclegacy, payrollMode: 'search'}); 
                break; 
            case 'payrollDateFrom':
                this.setState({payrollDateStart: value}); 
                break; 
            case 'payrollDateTo':
                this.setState({payrollDateTo: value}); 
                break;             
        }             
    }

    public handleSearch = () => {
        if(this.state.payrollDateStart == "" || this.state.payrollDateTo == ""){
            this.props.setAppToast({message: "Start and End Dates must be populated", type: AppToastTypes.Failure });            
            return; 
        }
        let endDate = new Date(this.state.payrollDateTo);         
        endDate.setDate(endDate.getDate() + 2); 
    
        const newPayrollFees = this.props.adjusterFeesList.resourceList.filter(
            x => x.adjusterBalance > 0 
            && x.lastPaymentDate >= this.state.payrollDateStart 
            && x.lastPaymentDate <= endDate.toDateString()
            && x.insCompanyName === (this.state.company === '' ? x.insCompanyName : this.state.company)
        ); 
        if (newPayrollFees.length > 0) {
            this.setState({payrollFees: this.state.payrollFees.filter(f => f.isAdvance).concat(newPayrollFees)}); 
        }
        else 
            this.props.setAppToast({message: "Zero Fees to Payout...", type: AppToastTypes.Failure });
    }

    public toggleAdvancedModal = () => {
        this.setState({showAdvancedModal: !this.state.showAdvancedModal}); 
    }

    public advancedModalCallback =(advanceFees: models.AdjusterFee[]) => {        
        const newFeesArray = this.state.payrollFees.concat(advanceFees.map(x => ({...x, isAdvance: true})));         
        this.setState({payrollFees: [...newFeesArray]}); 
    }

    public sumAdjusters = (fees: models.AdjusterFee[]) => {
        let returnValue: feesByAdjuster[] = new Array<feesByAdjuster>();

        fees?.forEach((item) => {
            const i: number = returnValue.findIndex(x => x.adjuster === item.adjuster);             
            if(i > -1){
                returnValue[i].sum += item.isAdvance ? item.adjusterTotal : item.adjusterBalance;
                returnValue[i].feeCount += 1; 
                returnValue[i].advSum += (item.isAdvance ? item.adjusterTotal : 0); 
            }
            else {                
                returnValue.push({
                    adjuster: item.adjuster, 
                    feeCount: 1, 
                    sum: (item.isAdvance ? item.adjusterTotal : item.adjusterBalance), 
                    advSum: (item.isAdvance ? item.adjusterTotal : 0), 
                    fees: fees.filter(x => x.adjuster === item.adjuster)
                }); 
            }
        }
        ); 
        return returnValue; 
    }

    public clearForm = () => {        
        this.setState({
            buttonText: 'FINALIZE',  
            closeNotify: false, 
            comment: '', 
            company: '', 
            inclegacy: true, 
            payrollFees: new Array<models.AdjusterFee>,            
            payrollDateStart: '',  
            payrollDateTo: '',     
            payrollMode: 'init',
            showAdvancedModal: false, 
            showProgressBar: false, 
        });
    }    

    public resolveStatusToPercent = () => {
        const { payrollStatus } = this.props; 
        const searchValue = payrollStatus.status.toLowerCase().includes("gathering") ? "gathering" : payrollStatus.status.toLowerCase().includes("building") ? "building" : payrollStatus.status.toLowerCase(); 

        if(this.state.buildComplete)
                return 100; 
        if(searchValue === "building"){
            const base = payrollStatus.status.replace("BUILDING", "").trim().split(' of ');             
            const adjusterCount = parseInt(base[1]); 
            const currentFile = parseInt(base[0]);             
            const percent = adjusterCount > 0 && currentFile > 0 ? (currentFile / adjusterCount) * 100 : 20;             
            return Math.round(percent); 
        }
        return 0;
    }

    public resolveBuildStage = () => {
        const { status } = this.props.payrollStatus; 

        if(this.props.isZipDownloading)
            return 'Downloading ZIP'; 
        if(status.toLowerCase() === 'building totals file')
            return 'Building Totals File'; 
        if(status.toLowerCase().includes('building'))
            return 'Building Files'
        if(status.toLowerCase() === 'completed')
            return 'Complete'; 
        if(status.toLowerCase() === 'fetching details')
            return 'Fetching Details'; 
        if(status.toLowerCase().includes('gathering'))
            return 'Gathering'
        if(status.toLowerCase() === 'queued')
            return 'Queued w/ File Builder'; 
        if(status.toLowerCase() === 'zipping files')
            return 'Creating ZIP Archive';         
        return ""; 
    }

    public render() {     
        const { 
            buttonText, 
            closeNotify, 
            comment, 
            inclegacy, 
            payrollDateStart, 
            payrollDateTo, 
            payrollFees, 
            payrollMode, 
            showAdvancedModal, 
            showProgressBar 
        } = this.state;
        const { 
            adjusterFeesList, 
            insCompanyDD, 
            isAdjusterFeesLoading, 
            isInsCompanyDDLoading, 
            isPayrollRunning, 
            payrollStatus, 
            showModal 
        } = this.props; 
        
        const advancesByAdjuster = this.sumAdjusters(payrollFees);        
        const feeCount = [...new Set(payrollFees?.map((item) => item.adjuster))].length || 0; 
        const advanceCount = [...new Set(payrollFees.filter(x => x.isAdvance).map((i) => i.adjuster))].length || 0; 
        const advanceAmount = payrollFees?.filter(x => x.isAdvance).reduce((a, c) => a = a + c.adjusterTotal, 0); 
        const adjusterCount = [...new Set(payrollFees.map((i) => i.adjuster))].length || 0; 
        const totalAmount = payrollFees?.reduce((a, c) => a = a + (c.isAdvance ? c.adjusterTotal : c.adjusterBalance), 0);

        const advanceFeeCandidates = adjusterFeesList.resourceList.filter(x => x.feesPaid == 0); 
        const advanceAdjusterIds = advanceFeeCandidates.map((i) => i.adjusterId); 

        const payrollAccess = userHasPermission("Access Payroll"); 
        const progressPercent = this.resolveStatusToPercent(); 
        const progressMessage = payrollStatus.status; 
        const buildStage = this.resolveBuildStage(); 

        return (
            <>
                <PayrollAdvanceModal 
                    adjustersList={adjusterFeesList.payrollAdjusters.filter(x => advanceAdjusterIds.includes(x.id))}
                    advanceCandidates={advanceFeeCandidates}
                    showModal={showAdvancedModal}
                    closeModal={this.toggleAdvancedModal}
                    callback={this.advancedModalCallback}
                />
                <Modal fullscreen show={showModal} onHide={this.resetFormStateAndClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Payroll</Modal.Title>
                    </Modal.Header>               
                    <Form onSubmit={this.submitForm}>
                        <Modal.Body>
                            <CardGroup>
                                <Card className="claim-card">  
                                    <Card.Title className="inv-card-title">Setup</Card.Title>
                                    <CardBody>
                                        <Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Payroll Dt Start</Form.Label>                                    
                                                    <Form.Control 
                                                        disabled={!payrollAccess}
                                                        type="date"
                                                        name="payrollDateFrom" 
                                                        value={payrollDateStart} 
                                                        onChange={(e) => this.handleFilters(e)}
                                                    />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Payroll Dt End</Form.Label>                                    
                                                    <Form.Control 
                                                        disabled={!payrollAccess}
                                                        type="date"
                                                        name="payrollDateTo" 
                                                        value={payrollDateTo} 
                                                        onChange={(e) => this.handleFilters(e)}
                                                    />
                                            </Form.Group>
                                        </Row>
                                        <Row style={{paddingTop: ".5em"}}>
                                            <Col sm={8}>
                                                <Form.Group controlId="insCompany" className="input-field-container2">
                                                    <Form.Label column sm="4">Company Name</Form.Label>
                                                    <Form.Select style={{marginLeft: "1em"}} 
                                                        name="insCompany" 
                                                        value={this.state.company} 
                                                        onChange={(e) => this.handleFilters(e)}
                                                        >
                                                            {isInsCompanyDDLoading && <option>Loading...</option>}
                                                            {!isInsCompanyDDLoading && <option key={-1}>Choose...</option>}                                        
                                                            {insCompanyDD.totalCount > 0 && insCompanyDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                                <option key={i} value={c.name} >{c.name}</option>
                                                            ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="isCat" className="input-field-container2">
                                                    <Form.Check 
                                                        inline={true}
                                                        name="incLegacy"
                                                        type="checkbox"
                                                        label="Include Legacy"
                                                        id="chkIncLegacy"
                                                        checked={inclegacy}
                                                        onChange={(e) => this.handleFilters(e)}
                                                    />                                
                                                </Form.Group>   
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Group controlId="comment" className="input-field-container2">
                                                <Col sm={{span: 3}}>
                                                    <Form.Label>Comment</Form.Label>
                                                </Col>
                                                <Col sm={{span: 9}}>
                                                    <Form.Control
                                                        name="comment"
                                                        disabled={!payrollAccess}                                                     
                                                        as="textarea"                                         
                                                        value={comment || ""} 
                                                        onChange={(e) => this.handleFilters(e)}
                                                    />
                                                </Col>
                                            </Form.Group>    
                                        </Row>
                                        <Row className="payroll-btn-grp">
                                            <Col>
                                                <Button 
                                                    disabled={!payrollAccess || payrollMode === 'init'} 
                                                    onClick={this.clearForm}
                                                >RESET</Button>
                                            </Col>
                                            <Col>
                                                <Button 
                                                    disabled={!payrollAccess || payrollMode === 'executing'} 
                                                    onClick={this.toggleAdvancedModal}
                                                >ADVANCE</Button>
                                            </Col>
                                            <Col>
                                                <Button 
                                                    onClick={this.handleSearch}
                                                    disabled={!payrollAccess || (payrollMode !== "search" && payrollMode === 'executing')}
                                                    >{isAdjusterFeesLoading ? "LOADING..." : "SEARCH"}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="claim-card">         
                                    <Card.Title className="inv-card-title">Summary
                                    </Card.Title>
                                    <CardBody className='payroll-summary'>
                                        <Col>
                                            <Row>
                                                <Col>Start Date: </Col>
                                                <Col>{payrollDateStart && dateFormat(payrollDateStart, "mediumDate", true)}</Col>
                                            </Row>
                                            <Row>
                                                <Col>End Date: </Col>
                                                <Col>{payrollDateTo && dateFormat(payrollDateTo, "mediumDate", true)}</Col>
                                            </Row>
                                            <Row>
                                                <Col>Advances: </Col>
                                                <Col>{advanceCount}</Col>
                                            </Row>
                                            <Row>
                                                <Col>Adv Amt: </Col>
                                                <Col><NumberFormat value={advanceAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                                            </Row>
                                            <Row>
                                                <Col>Adjusters: </Col>
                                                <Col>{adjusterCount}</Col>
                                            </Row>
                                            <Row>
                                                <Col>Total Amt: </Col>
                                                <Col><NumberFormat value={totalAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                                            </Row>
                                        </Col>
                                        <Col className='payroll-summary-img'>
                                            {payrollMode === "complete" && <img src={iconCheck} style={{width: "15%"}}/>}                                            
                                            <div style={{marginTop: "2.5em"}}>
                                                <Button 
                                                    disabled={!payrollAccess || payrollMode !== "ready" || isPayrollRunning} 
                                                    type='submit'                                        
                                                >       
                                                    {isPayrollRunning && <Spinner as="span" animation="grow" role="status" aria-hidden="true" size='sm'/>}
                                                    <span>&nbsp;&nbsp;{buttonText}</span>
                                                </Button>
                                                <Form.Group 
                                                    controlId="isNotify" 
                                                    className="input-field-container2"
                                                    style={{marginLeft: "1em"}}
                                                >
                                                    <Form.Check 
                                                        inline={true}
                                                        name="isNotify"
                                                        type="checkbox"
                                                        label="Close & Notify"
                                                        id="chkIsNotify"
                                                        checked={closeNotify}
                                                        onChange={(e) => this.setState({closeNotify: !closeNotify})}
                                                    />                                
                                                </Form.Group>
                                            </div>
                                        </Col>                                        
                                    </CardBody>
                                    {showProgressBar && 
                                        <div style={{paddingLeft: "1em", paddingRight: "1em"}}>Payroll {buildStage || 'Initializing'}...
                                            <ProgressBar 
                                                now={progressPercent}
                                                label={`${progressMessage}`}
                                            />
                                        </div>
                                    }
                                </Card>
                            </CardGroup>
                            {feeCount > 0 &&
                            <Card className="claim-card">
                                <Card.Title className="inv-card-title">Adjuster Payroll Fees</Card.Title>
                                <Card.Body>
                                    <Accordion className="inv-acc-card" alwaysOpen>
                                        {advancesByAdjuster?.map((x: feesByAdjuster, i:number) => {
                                            return (
                                                <Accordion.Item key={i} eventKey={x.adjuster}>
                                                    <Accordion.Header className="inv-acc-hdr">
                                                        <Col>Adjuster: {x.adjuster}</Col>
                                                        <Col>Fees: {x.feeCount}</Col>                                                     
                                                        <Col>Advance Amt: <NumberFormat value={x.advSum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                                        <Col>Total: <NumberFormat value={x.sum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <AdjusterFeeTable
                                                            adjusterFees={x.fees}
                                                            showAdvance={true}
                                                        />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ); 
                                        })}
                                    </Accordion>      
                                </Card.Body>
                            </Card>
                            }                    
                        </Modal.Body>                                       
                    </Form>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    adjusterFeesList: state.payroll.payroll.adjusterFeeResponse, 
    isAdjusterFeesLoading: state.payroll.payroll.isAdjusterFeesLoading,    
    insCompanyDD: state.companies.dropdowns.insCompanyDD,
    isInsCompanyDDLoading: state.companies.dropdowns.isInsCompanyDDLoading, 
    isPayrollRunning: state.payroll.payroll.isPayrollRunning, 
    isZipDownloading: state.payroll.payroll.isZipDownloading, 
    payrollListRequest: state.payroll.search.payrollListRequest, 
    payrollStatus: state.pollPayroll.pollPayroll.payrollStatus,
    postedPayrollId: state.payroll.payroll.postPayrollResponse, 
}); 

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {       
            addPayroll: payrollActions.addPayroll,
            startPolling: pollPayrollActions.startPoll, 
            stopPolling: pollPayrollActions.stopPoll,                         
            requestAdjusterFees: payrollActions.requestAdjusterFees,
            requestInsCompanyDD: companyActions.requestInsCompanyDD,
            requestPayrollList: payrollActions.requestPayrollList, 
            requestPayrollZipFile: payrollActions.requestPayrollZipFile, 
            setAppToast: userActions.setAppToast,             
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(PayrollDetail);
