import * as React from 'react';
import { Col, Form, Table } from 'react-bootstrap'; 
import * as models from '../../models';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';

interface Props {    
    adjusterFees: models.AdjusterFee[];
    selectedItems?: number[];
    showAdvance?: boolean;
    toggleItem?: (id:number) => void;  
}

interface State {    
}

class AdjusterFeeTable extends React.Component<Props, State> {
    public state = {        
    };    

    public render() {
        const { adjusterFees, selectedItems, showAdvance, toggleItem } = this.props;        

        return (
            <>
            {adjusterFees[0] &&
                <Table striped>
                    <thead>
                        <tr>
                            {selectedItems && <th></th>}
                            <th>Invoice #</th>
                            <th>Claim #</th>
                            <th>Invoice Dt</th>
                            <th>Fee Name</th>
                            <th>Fee Amt</th>                            
                            <th>Adjuster Amount</th>
                            <th>Advanced Amount</th>
                            <th>Total Payrolled</th>
                            <th>Adjuster Balance</th>
                            {showAdvance && <th>Advance</th>}
                            <th>Payments Rcvd</th>
                        </tr>
                    </thead>
                    <tbody>
                    {adjusterFees.map((x: models.AdjusterFee, i: number) => (
                        <tr key={i}>
                            {toggleItem &&
                            <td>
                                <Form.Group as={Col} controlId="isChecked" className="input-field-container">
                                    <Form.Check 
                                        name="isChecked"
                                        type="checkbox"
                                        id="chkSelected"
                                        value={x.invoiceFeeId}
                                        checked={selectedItems?.includes(x.invoiceFeeId)}
                                        onChange={() => toggleItem(x.invoiceFeeId)}
                                    />                                
                                </Form.Group>
                            </td> }
                            <td>{x.invoiceNumber}</td>
                            <td>{x.insClaimNumber}</td>
                            <td>{dateFormat(x.invoiceDt, 'mm/dd/yyyy')}</td>
                            <td>{x.feeName}</td>
                            <td><NumberFormat value={x.feeAmt?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                            <td><NumberFormat value={x.adjusterTotal?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            <td><NumberFormat value={x.advancePayrolled?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            <td><NumberFormat value={x.totalPayrolled?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            <td><NumberFormat value={x.adjusterBalance?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            {showAdvance && <td>{x.isAdvance ? "Yes" : "No"}</td>}
                            <td><NumberFormat value={x.feesPaid?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                                                                            
                        </tr>
                    ))}
                    </tbody>
                </Table>
            }
            </>
        );
    }
}
export default AdjusterFeeTable;
