import * as models from '..';

export interface PayrollAdjuster {
    adjusterId: number; 
    adjuster: string; 
    fees: models.PayrollFee[]; 
}

export interface PayrollFee {
    claimNumber: string; 
    invoiceNumber: string; 
    invoiceDate: string; 
    feeName: string; 
    feeAmt: number; 
    adjusterPercent: number; 
    paid: number; 
    paymentDate: string; 
    amount: number; 
    isAdvance: boolean; 
}

export interface PayrollDetail {
    id: number; 
    runDate: string; 
    payrollName: string; 
    startDate: string;
    endDate: string; 
    comments: string; 
    status: string; 
    storageFolder: string; 
    advanced: number; 
    total: number;     
    createdBy: string; 
    adjusters: PayrollAdjuster[]; 
}

export const defaultPayrollFee: PayrollFee = {
    claimNumber: '', 
    invoiceNumber: '', 
    invoiceDate: '',
    feeName: '', 
    feeAmt: 0,
    adjusterPercent: 0,
    paid: 0, 
    paymentDate: '', 
    amount: 0, 
    isAdvance: false, 
}

export const defaultPayrollDetail: PayrollDetail = { 
    id: 0, 
    runDate: '', 
    payrollName: '', 
    startDate: '', 
    endDate: '', 
    comments: '',     
    status: '', 
    storageFolder: '', 
    advanced: 0, 
    total: 0,
    createdBy: '',  
    adjusters: [] 
}