import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import AdminReducer from './admin/reducer'; 
import ClaimsReducer from './claims/reducer'; 
import CompaniesReducer from './companies/reducer'; 
import ContactsReducer from './contacts/reducer'; 
import DashboardReducer from './dashboard/reducer'; 
import FinancialsReducer from './financials/reducer'; 
import LocationsReducer from './locations/reducer'; 
import PayrollReducer from './payroll/reducer'; 
import PolicyReducer from './policies/reducer'; 
import UserReducer from './user/reducer'; 
import SecurityReducer from './security/reducer';
import PollPayrollReducer from './pollPayroll/reducer'; 

// Create browser history to use in the Redux store
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
//export const history = createBrowserHistory({ basename: baseUrl });
export const history = createBrowserHistory();

const rootReducer = combineReducers({
    admin: AdminReducer,
    claims: ClaimsReducer, 
    companies: CompaniesReducer,
    contacts: ContactsReducer, 
    dashboards: DashboardReducer, 
    financials: FinancialsReducer,
    locations: LocationsReducer,
    payroll: PayrollReducer, 
    policy: PolicyReducer, 
    router: connectRouter(history),    
    user: UserReducer,
    security: SecurityReducer,
    pollPayroll: PollPayrollReducer, 
});

export default rootReducer; 