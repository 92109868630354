import * as React from 'react'; 
import Chart from 'react-apexcharts'; 
import { Card } from 'react-bootstrap'; 
import * as models from '../../../models'; 
import { ApexOptions } from 'apexcharts';
import _ from 'lodash'; 

interface Props {
    payrollByAdjuster: models.PayrollByAdjuster[]
}

interface State {
    series: any;  
    options: ApexOptions; 
}

class Top10AdjustersTYD extends React.PureComponent<Props, State> {    
    public state = {
        series: [],
        options: {}
    }    

    public componentDidMount = () => {
        if(this.props.payrollByAdjuster.length > 0)
            this.loadDataToChart();     
    }  

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if(!_.isEqual(prevProps.payrollByAdjuster, this.props.payrollByAdjuster) && this.props.payrollByAdjuster.length > 0){
            this.loadDataToChart(); 
        }        
    }

    loadDataToChart = () => {
        this.setState({
            options: {
                chart: {
                    id: "basic-bar"
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    }
                },  
                xaxis: {
                    categories: this.props.payrollByAdjuster.map(x => x.adjuster),
                    labels: {
                        formatter: function (value) {
                            return "$" + value; 
                        }
                    } 
                },
                title: {
                    text: "YTD Top 10 Adjusters",
                    align: 'center'
                },
                dataLabels: {
                    formatter: function(value:number) {
                        return "$" + value.toFixed(2); 
                    },                                        
                }
            }, 
            series: [
                {
                    name: "Total Payrolled",
                    data: this.props.payrollByAdjuster.map(x => x.payrollAmt)                    
                }
            ]      
        }); 
    }

    render() {        
        return (
            <Card>
                <Chart 
                    options={this.state.options} 
                    series={this.state.series} 
                    type="bar" 
                    height={575} 
                />
            </Card>     
        );
    }
}
    
export default Top10AdjustersTYD; 