export const ADD_PAYROLL = 'payroll/ADD_PAYROLL'; 
export const ADD_PAYROLL_COMPLETE = 'payroll/ADD_PAYROLL_COMPLETE'; 
export const CLEAR_ADJUSTER_FEES_LIST = 'payroll/CLEAR_ADJUSTER_FEES_LIST'; 
export const DELETE_PAYROLL = 'payroll/DELETE_PAYROLL'; 
export const REBUILD_PAYROLL_FILES = 'payroll/REBUILD_PAYROLL_FILES'; 
export const REQUEST_ADJUSTER_FEES = 'payroll/REQUEST_ADJUSTER_FEES'; 
export const RECEIVE_ADJUSTER_FEES = 'payroll/RECEIVE_ADJUSTER_FEES'; 
export const REQUEST_ADJUSTER_FEES_LIST = 'payroll/REQUEST_ADJUSTER_FEES_LIST'; 
export const RECEIVE_ADJUSTER_FEES_LIST = 'payroll/RECEIVE_ADJUSTER_FEES_LIST'; 
export const REQUEST_ADJUSTER_FEES_LIST_CSV = 'payroll/REQUEST_ADJUSTER_FEES_LIST_CSV'; 
export const RECEIVE_ADJUSTER_FEES_LIST_CSV = 'payroll/RECEIVE_ADJUSTER_FEES_LIST_CSV'; 
export const REQUEST_PAYROLL_LIST = 'payroll/REQUEST_PAYROLL_LIST'; 
export const RECEIVE_PAYROLL_LIST = 'payroll/RECEIVE_PAYROLL_LIST'; 
export const REQUEST_PAYROLL = 'payroll/REQUEST_PAYROLL'; 
export const RECEIVE_PAYROLL = 'payroll/RECEIVE_PAYROLL';  
export const REQUEST_PAYROLL_CSV = 'payroll/REQUEST_PAYROLL_CSV'; 
export const RECEIVE_PAYROLL_CSV = 'payroll/RECEIVE_PAYROLL_CSV'; 
export const REQUSET_PAYROLL_ZIPFILE = 'payroll/REQUSET_PAYROLL_ZIPFILE';
export const RECEIVE_PAYROLL_ZIPFILE = 'payroll/RECEIVE_PAYROLL_ZIPFILE'; 