import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, ButtonGroup, Card, CardGroup, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap'; 
import * as constants from '../../store/constants'; 
import GearButton from 'components/common/GearButton';
import * as models from '../../models';
import dateFormat from 'dateformat';
import services from '../../services'; 
import _ from 'lodash'; 
import NumberFormat from 'react-number-format'; 
import { CardBody } from 'reactstrap';
import { financialsActions } from 'store/financials';
import FeeScheduleSuggestionModal from './FeeScheduleSuggestionModal'; 
import InvoiceSummaryTable from './InvoiceSummaryTable';
import redInfoIcon from '../../assets/images/info-circle-red.svg'; 
import { claimsActions } from 'store/claims';
import { userHasPermission } from '../../utils/helpers';
import { USER_PERMISSIONS } from 'store/enums';
import * as helpers from '../../utils/helpers'; 
import { userActions } from 'store/user';
import { AppToastTypes } from 'models/enums'; 
import { USER_ACTIONS } from 'store/enums';

interface Props extends PropsFromRedux {
    claimId: number; 
    invoiceId: number; 
    closeModal: () => void; 
    showModal: boolean;     
}

interface feeLKP {fee:string, percent:number}; 

interface State {    
    adjusterFeeLKP: feeLKP[]; 
    disableSave: boolean; 
    fee: models.InvoiceFee;
    feeEditMode: boolean; 
    feeScheduleId: number; 
    feeScheduleSuggestions: models.FeeScheduleSuggestionResponse; 
    feeUnpdateIndex: number; 
    invoice: models.Invoice;
    isSuggestionsLoading: boolean;
    saveMode: string;     
    serviceFee: models.InvoiceFee; 
    serviceFeeMode: string; 
    showFeeModal: boolean;  
    showSuggestionModal: boolean;     
    validated: boolean; 
}

const initServiceFee: models.InvoiceFee = {
    ...models.defaultInvoiceFee,        
    feeName: 'Service',                 
    quantity: 1, 
}

class Invoice extends React.Component<Props, State> {
    public initState () {
        return {
            adjusterFeeLKP: new Array<feeLKP>(), 
            disableSave: false, 
            fee: models.defaultInvoiceFee,
            feeEditMode: false, 
            feeScheduleId: 0, 
            feeScheduleSuggestions: models.defaultResponse, 
            feeUnpdateIndex: -1, 
            invoice: models.defaultInvoice,
            isSuggestionsLoading: false, 
            saveMode: '', 
            serviceFee: initServiceFee, 
            serviceFeeMode: 'schedule', 
            showFeeModal: false, 
            showSuggestionModal: false,
            validated: false, 
        }
    }
    public state = this.initState();         

    // lifecycle methods
    async componentDidMount () {
        this.props.getFeesList();
    }   

    componentDidUpdate(prevProps: any, prevState: any) {
        const { adjusterFeeLKP, fee, feeEditMode, saveMode } = this.state; 
        const { 
            buildPDF, 
            claim, 
            claimId,
            connectAdjusterFees,
            draftInvoice, 
            feeScheduleList, 
            invoice, 
            invoiceId, 
            lastInvCreatedId, 
            lastUserAction, 
            requestClaimSingle, 
            requestInvoice, 
            requestInvoiceDraft, 
            showModal 
        } = this.props; 

        if(!_.isEqual(prevProps.showModal, showModal) && showModal) { // opening screen, get invoice & claim
            if(claimId !== 0)
                requestClaimSingle(claimId); 
            
            // get draft or invoice (create or edit)
            if(invoiceId === 0) {
                requestInvoiceDraft(this.props.claimId); 
            }
            else {
                requestInvoice(invoiceId); 
            }
            
            if(!_.isEqual(prevProps.claim, claim) 
                || feeScheduleList.totalCount === 0 
                || feeScheduleList.resourceList[0].insuranceCompany !== claim.insuranceCompany
                || !connectAdjusterFees.serviceFeePercent
            ){
                this.getClaimRelatedData();
            }
        }
        
        // build PDF for newly created invoice
        if(!_.isEqual(prevProps.lastInvCreatedId, lastInvCreatedId) && lastInvCreatedId && saveMode === "savePDF"){
            buildPDF(lastInvCreatedId);
        }

        // invoice saved/created successfully, create PDF or reset & close modal
        if (!_.isEqual(prevProps.lastUserAction, lastUserAction) 
            && [USER_ACTIONS.INVOICE_SAVING_SUCCESS, USER_ACTIONS.CLAIM_CREATE_INVOICE_SUCCESS].includes(lastUserAction)) 
        {       
            if(saveMode === "savePDF" && lastUserAction === USER_ACTIONS.INVOICE_SAVING_SUCCESS){
                buildPDF(this.state.invoice.invoiceId); 
            }
            if(saveMode !== "savePDF" && showModal) 
                this.resetFormStateAndClose();            
        }         

        // build PDF success, reset & close modal
        if (!_.isEqual(prevProps.lastUserAction, lastUserAction) 
            && [USER_ACTIONS.INVOICE_BUILD_PDF_SUCCESS].includes(lastUserAction) 
            && showModal) {       
                this.resetFormStateAndClose()
        }

        // invoice save or create failed, reset form for retries
        if (!_.isEqual(prevProps.lastUserAction, lastUserAction) 
            && [USER_ACTIONS.CLAIM_CREATE_INVOICE_FAILURE, USER_ACTIONS.INVOICE_SAVING_FAILURE].includes(lastUserAction) 
            && showModal) {       
            this.resetOnError(); 
        }

        // invoice received, update state
        if (!_.isEqual(prevProps.invoice, invoice) && invoice !== undefined) {
            const serviceFee: models.InvoiceFee = invoice.invoiceFees?.find(x => x.feeName.toLocaleLowerCase() === 'service') || initServiceFee; 
            this.setState({invoice: structuredClone(invoice,), serviceFee: serviceFee});              
        }
        // updated invoice state with draft invoice
        if (!_.isEqual(prevProps.draftInvoice, draftInvoice)) {       
            this.setState({invoice: {...draftInvoice}, serviceFee: {...this.state.serviceFee, comment: 'Gross Loss $' + draftInvoice.grossLoss.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}});             
        }
        // adjuster fees received, add to state
        if(!_.isEqual(prevProps.connectAdjusterFees, connectAdjusterFees)) {   
            const serviceFeePercent = connectAdjusterFees?.serviceFeePercent || 0;             
            const tAndEPercent = connectAdjusterFees?.tAndEPercent || 0; 
            const photosPercent = connectAdjusterFees?.photosPercent || 0;
            const miscExpsnsePercent = connectAdjusterFees?.mileagePercent || 0;
            const MileagePercent = connectAdjusterFees?.mileagePercent || 0; 
            const newLKP: {fee:string, percent:number}[] = [
                {fee: "service", percent: serviceFeePercent},
                {fee: "tande", percent: tAndEPercent},
                {fee: "photos", percent: photosPercent},
                {fee: "misc", percent: miscExpsnsePercent},
                {fee: "mileage", percent: MileagePercent},
            ]; 
            this.setState({adjusterFeeLKP: newLKP, serviceFee: {...this.state.serviceFee, adjusterPercent: serviceFeePercent}}); 
        }

        // Fee Name changed in editor, retrieve rates
        if(!_.isEqual(prevState.fee.feeName, fee.feeName) && !feeEditMode) {
            const percent = adjusterFeeLKP.find(f => f.fee === fee.feeName.toLocaleLowerCase())?.percent || 0; 
            this.setState({
                fee: {
                    ...fee, 
                    adjusterPercent: percent
                }
            }); 
        }        
    }  

    // helpers
    public getClaimRelatedData = () => {
        const { claim } = this.props; 
        this.props.requestConnectAdjusterFees(claim.adjuster.id);
        this.props.getFeeSchedules(claim.insuranceCompany); 
    }

    // form methods
    public submitForm = (e:any) => {
        const mode = e.target.value; 
        const form = e.currentTarget; 
        e.preventDefault();
        const missingComments = this.state.invoice.invoiceFees?.filter(x => x.feeName.toLocaleLowerCase() !== 'admin' && x.comment === null).length || 0; 
        if(missingComments > 0) {
            this.props.setAppToast({message: "All non-admin fees must contain comments", type: AppToastTypes.Failure });            
            return; 
        }
        if(this.state.invoice.grandTotal === 0) {
            this.props.setAppToast({message: "Invoice must be > $0.00!", type: AppToastTypes.Failure });            
            return; 
        }
        
        this.setState({disableSave: true, saveMode: mode, validated: true});
        if(form.checkValidity() === false){                        
            e.stopPropagation(); 
        } 
        else {
            if(this.state.invoice.invoiceId === 0) {
                this.props.addClaimInvoice(this.props.claim.claimID, this.state.invoice);
                this.props.clearSelectedInvoices(); 
            }
            else {
                if(this.state.invoice.grandTotal == 0 && !userHasPermission("Can delete/zero out invoice")){                    
                    this.props.setAppToast({message: "User not authorized to zero out invoice.", type: AppToastTypes.Failure });                 
                    this.setState({validated: false}); 
                }
                else {
                    this.props.replaceInvoice(this.props.invoice.invoiceId, this.state.invoice); 
                }
            }            
        }        
    }

    public resetOnError = () => {
        this.setState({disableSave: false, validated: false}); 
    }

    public resetFormStateAndClose = () => {        
        this.setState(this.initState()); 
        this.props.clearInvoice(); 
        this.props.closeModal(); 
    }

    // fee schedule methods
    public toggleSuggestionModal = () => {
        this.setState({showSuggestionModal: !this.state.showSuggestionModal}); 
    }

    public getSuggestions = async () => {
        this.setState({isSuggestionsLoading: !this.state.isSuggestionsLoading}); 
        const schedulesResponse = await services.api.claim.getScheduleSuggetions(this.props.claim.claimID); 
        if (schedulesResponse.isSuccess){
            const suggestions: models.FeeScheduleSuggestionResponse = schedulesResponse.data;
            this.setState({feeScheduleSuggestions: suggestions }); 
            if (this.props.showModal && suggestions?.totalCount > 0)                        
                this.toggleSuggestionModal(); 
        }
        this.setState({isSuggestionsLoading: !this.state.isSuggestionsLoading}); 
    }

    public suggestionCallBack = (scheduleId: number) => {
        const feeScheduleName = this.props.feeScheduleList.resourceList.find(x => x.feeScheduleId === scheduleId)?.scheduleName || ""; 
        this.setState({feeScheduleId: scheduleId, invoice: {...this.state.invoice, feeSchedule: feeScheduleName}}); 
    }

    public handleApplySchedule = async () => {
        const { invoice, serviceFee, serviceFeeMode } = this.state; 
        const { setAppToast } = this.props;         

        if(!serviceFee.comment) {
            setAppToast({message: 'Service Fee must have comment.', type: AppToastTypes.Failure}); 
            return; 
        }
        if(serviceFeeMode === 'schedule'){
            const request: models.AddFeeScheduleToInvoice = {
                claimId: this.props.claim.claimID,
                grossLossAmt: invoice.grossLoss,
                teQty: invoice.hours || 0.00,
                adjusterId: this.props.claim.adjuster?.id,   
                isTandE: invoice.isTandE,          
                taxRate: invoice.taxRate,  
            }
            const invoiceFeeResponse = await services.api.financials.addFeeScheduleToInvoice(this.state.feeScheduleId, request); 
                    
            if (invoiceFeeResponse.isSuccess)
            {               
                if(invoiceFeeResponse.data.totalCount === 0)
                    this.props.setAppToast({message: "No fees applied...", type: AppToastTypes.Failure });                 
                else {          
                    const mergedFees = this.mergeFees(invoiceFeeResponse.data.resourceList);
                    this.calculateTotals(mergedFees); 
                }
            }
        }
        else {
            const mergedInvoiceFees = this.mergeFees(new Array<models.InvoiceFee>().fill(serviceFee)); 
            this.calculateTotals(mergedInvoiceFees);
        }
    }

    // fee management methods
    public mergeFees = (fees: models.InvoiceFee[]) => {
        const { invoice, serviceFee } = this.state; 
        let mergedFees: models.InvoiceFee[] = new Array<models.InvoiceFee>; 
        fees.forEach((x: models.InvoiceFee) => {
            const currFee = invoice.invoiceFees?.find(f2 => f2.feeName === x.feeName);                     
            if(currFee == undefined) { 
                if(x.feeName.toLocaleLowerCase() === "admin" && invoice.claimHasAdminFee) 
                    return; 
                const newFee: models.InvoiceFee = {
                    ...x,
                    comment: x.feeName.toLocaleLowerCase() === 'service' ? serviceFee.comment : '', 
                    total: x.total,
                    adjusterPercent: this.state.adjusterFeeLKP.find(f => f.fee === x.feeName.toLocaleLowerCase())?.percent || 0, 
                    isTaxable: invoice.taxRate > 0 ? x.isTaxable : false,
                }
                mergedFees.push(newFee); 
            }
            else {               
                const qty = (invoice.isTandE ? invoice.hours! : currFee.quantity);                 
                const newFee: models.InvoiceFee = {
                    ...currFee,
                    quantity: qty,
                    rate: x.rate,
                    total: x.rate * qty,
                    adjusterPercent: currFee.adjusterPercent > 0 ? currFee.adjusterPercent : this.state.adjusterFeeLKP.find(f => f.fee === x.feeName.toLocaleLowerCase())?.percent || 0, 
                    isTaxable: invoice.taxRate > 0 ? x.isTaxable : false,
                }
                mergedFees.push(newFee); 
            }
        });
        const untouchedFees = invoice.invoiceFees?.filter(x => !fees.find(y => y.feeName === x.feeName)) || new Array<models.InvoiceFee>; 
        mergedFees = mergedFees.concat(untouchedFees); 
        return mergedFees; 
    }  
    
    public calculateFee = (fee: models.InvoiceFee): models.InvoiceFee => {
        return {
            ...fee,   
            total: fee.quantity * fee.rate
        }  
    }

    public validateFee = (fee: models.InvoiceFee) => {          
        const { invoice, feeEditMode } = this.state; 
        if(fee.feeName === "") return "Fee Type must be selected!";          
        if(fee.quantity === 0) return "Quantity must be greater than 0!"; 
        if(fee.rate === 0) return "Amount must be greater than 0!";         
        const adminFeeExists = invoice.claimHasAdminFee || invoice.invoiceFees?.some(x => x.feeName.toLocaleLowerCase() === 'admin'); 
        if(adminFeeExists && fee.feeName.toLocaleLowerCase() === 'admin' && !feeEditMode) return "Claim has already been charged an Admin fee."; 
        if((!fee.comment || fee.comment === "") && fee.feeName.toLocaleLowerCase() !== 'admin') return "Comment Required!";             
        if(fee.adjusterPercent > 100) return "Adjuster Percent must be less than 100!"; 
        return null; 
    }
    
    public addFeeToInvoice = (fee: models.InvoiceFee) => {  
        const validationError = this.validateFee(fee); 
        if(validationError !== null){
            this.props.setAppToast({message: validationError, type: AppToastTypes.Failure}); 
            return; 
        }        
        const { invoice } = this.state;               
        const feeToAdd: models.InvoiceFee = {
            ...fee,
            isTaxable: invoice.isTaxExempt || invoice.taxRate == 0 ? false : fee.isTaxable, 
            total: fee.quantity * fee.rate
        }         
        const newFees = invoice.invoiceFees?.concat(feeToAdd)!;
        this.calculateTotals(newFees);
    }    

    public handleSaveUpdatedFee = () => {
        const updatedFee = this.state.serviceFeeMode === 'edit' ? this.state.serviceFee : this.state.fee; 
        this.editInvoiceFee(updatedFee); 
    }
    public editInvoiceFee = (fee: models.InvoiceFee) => {
        const validationError = this.validateFee(fee); 
        if(validationError !== null){
            this.props.setAppToast({message: validationError, type: AppToastTypes.Failure}); 
            return; 
        }                        
        const { feeUnpdateIndex, invoice } = this.state; 
        let tempInvoiceFees = invoice.invoiceFees?.slice(); 
        tempInvoiceFees![feeUnpdateIndex] = {...this.calculateFee(fee)}; 
        this.calculateTotals(tempInvoiceFees!); 
    }

    public updateInvoiceFee = (i: number, fee: models.InvoiceFee) => {
        const { invoice } = this.state; 
        let tempInvoiceFees = invoice.invoiceFees?.slice(); 
        tempInvoiceFees![i] = {...this.calculateFee(fee)}; 
        this.calculateTotals(tempInvoiceFees!); 
    }

    public toggleTaxable = (rowId: number) => {
        const currentFee = this.state.invoice.invoiceFees![rowId];         
        const newFee = {
            ...currentFee,
            isTaxable: !currentFee.isTaxable,             
        }
        this.updateInvoiceFee(rowId, newFee); 
    }

    public handleAdditionalFee = () => {        
        const { fee, feeEditMode} = this.state;                    
        if(feeEditMode)
            this.handleSaveUpdatedFee(); 
        else
             this.addFeeToInvoice(fee); 
    }

    public createSalesTaxFee = (invoiceFees: models.InvoiceFee[]) => {
        const { invoice } = this.state; 
        const isTaxExempt = invoice.isTaxExempt;         
        let workingFees = invoiceFees.filter(x => x.feeName.toLocaleLowerCase() !== 'sales tax'); 

        if (!isTaxExempt && invoice.taxRate > 0) {
            const salesTaxAmt = workingFees.filter(x => x.isTaxable).reduce((a, b) => a + b.total, 0) * invoice.taxRate / 100; 
            const salesTaxFee: models.InvoiceFee = {
                ...models.defaultInvoiceFee,
                invoiceFeeId: invoiceFees.find(x => x.feeName.toLocaleLowerCase() === 'sales tax')?.invoiceFeeId || 0,
                feeName: "Sales Tax", 
                quantity: 1, 
                isTaxable: false, 
                rate: salesTaxAmt, 
                total: salesTaxAmt, 
            }
            workingFees.push(salesTaxFee);             
        }
        return workingFees; 
    }

    public calculateTotals = (invoiceFees: models.InvoiceFee[]) => {  
        const { invoice, serviceFee } = this.state;         
        let taxedFees = this.createSalesTaxFee(invoiceFees); 

        if (invoiceFees?.length !== 0){
            const subTotal = invoiceFees?.filter(x => x.feeName.toLocaleLowerCase() !== 'sales tax').reduce((acc, obj) => acc + obj.total, 0) || 0.00;            
            const salesTax = taxedFees.find(x => x.feeName.toLocaleLowerCase() === 'sales tax')?.total || 0; 
            const grandTotal = subTotal + salesTax;
            this.setState({
                invoice: {
                    ...invoice, 
                    invoiceFees: taxedFees, 
                    salesTax: salesTax, 
                    subTotal: subTotal, 
                    grandTotal: grandTotal
                },
                fee: {
                    ...models.defaultInvoiceFee
                },                 
                feeEditMode: false,
                feeUnpdateIndex: -1,
                serviceFee: invoiceFees.find(x => x.feeName.toLocaleLowerCase() === 'service') || serviceFee, 
                serviceFeeMode: 'schedule'
            }); 
        }
        else {
            this.setState({
                invoice: {
                    ...invoice, 
                    invoiceFees: taxedFees, 
                    salesTax: 0, 
                    subTotal: 0, 
                    grandTotal: 0
                },
                fee: {
                    ...models.defaultInvoiceFee
                },
                feeEditMode: false, 
                feeUnpdateIndex: -1, 
                serviceFee: invoiceFees.find(x => x.feeName.toLocaleLowerCase() === 'service') || serviceFee,
                serviceFeeMode: 'schedule'
            });
        }
    }

    public handleDeleteInvoiceFee = (rowId: number) => {            
        const copyInvoices = this.state.invoice.invoiceFees?.slice(); 
        copyInvoices?.splice(rowId,1); 
        const newFees = copyInvoices || new Array<models.InvoiceFee>; 
        const newInvoice: models.Invoice = {
            ...this.state.invoice
            , invoiceFees: newFees
        }
        this.setState({invoice: newInvoice});
        this.calculateTotals(newFees);
    }

    public loadFeeForEdit = (rowId: number) => {
        const feesList = {...this.state.invoice.invoiceFees}; 
        if(feesList[rowId].feeName.toLocaleLowerCase() !== 'service') {            
            this.setState({
                fee: {
                    ...feesList[rowId],                 
                },             
                feeUnpdateIndex: rowId, 
                feeEditMode: true
            }); 
        }
        else {
            this.setState({
                feeUnpdateIndex: rowId, 
                serviceFeeMode: 'edit'
            }); 
        }
    }    

    public handleFeeChange = (e:any) => {            
        const fee:models.Fee = {
            ...this.props.feesResponse.resourceList.find(x => x.feeName === e.target.value)!
        }; 
        this.setThisFee(fee); 
    }
    
    public toggleFeeModal = () => {
        this.setState({showFeeModal: !this.state.showFeeModal}); 
    }

    public setThisFee = (fee: models.Fee) => {        
        let adjPercent = 0; 
        switch (fee.feeName.toLocaleLowerCase()) {
            case "service":
                adjPercent = this.props.connectAdjusterFees.serviceFeePercent || 0; 
                break;
            case "tande":
                adjPercent = this.props.connectAdjusterFees.tAndEPercent || 0; 
                break; 
            case "mileage":
                adjPercent = this.props.connectAdjusterFees.mileagePercent || 0; 
                break; 
            case "misc":
                adjPercent = this.props.connectAdjusterFees.miscExpensePercent || 0; 
                break; 
            case "photos":
                adjPercent = this.props.connectAdjusterFees.photosPercent || 0; 
                break; 
        }        
        this.setState({
            fee: {
                ...models.defaultInvoiceFee, 
                feeName: fee.feeName, 
                rate: fee.defaultValue, 
                quantity: 1, 
                isTaxable: !fee.isTaxExempt, 
                adjusterPercent: adjPercent
            }
        }); 
    }

    public toggleTE = () => {
        const currentTE = this.state.invoice.isTandE; 
        let newTEQty = this.state.invoice.hours; 
        if(currentTE)
            newTEQty = undefined; 

        this.setState({invoice: {...this.state.invoice, isTandE: !currentTE, hours: newTEQty}})
    }

    public handleTaxRateChange = () => {
        if(this.state.invoice.invoiceFees && this.state.invoice.invoiceFees.length > 0) {
            this.calculateTotals(this.state.invoice.invoiceFees); 
        }        
    }
    
    public render() {
        const { claim, 
                connectAdjusterFees, 
                feesResponse, 
                feeScheduleList, 
                invoices, 
                insuredPerson, 
                isClaimLoading, 
                isConnectAdjusterFeesLoading, 
                isDraftInvoiceLoading, 
                isFeeScheduleListLoading, 
                isInvoiceLoading, 
                isInvoicesLoading, 
                showModal,               
            } = this.props; 
        const { disableSave, 
                fee, 
                feeEditMode, 
                feeScheduleId, 
                feeScheduleSuggestions, 
                invoice, 
                isSuggestionsLoading, 
                serviceFee, 
                serviceFeeMode,
                showSuggestionModal, 
                validated 
            } = this.state; 
        const cityState = (insuredPerson?.mailingAddress?.city || '')  + ', ' + (insuredPerson?.mailingAddress?.state || '');        
        const canEditTaxRate = userHasPermission("Can edit sales tax on invoices"); 
        const isPageDataLoading = isClaimLoading || isDraftInvoiceLoading || isInvoiceLoading || isInvoicesLoading || isConnectAdjusterFeesLoading;  
        const disableAllEdit: boolean = (invoice.invoiceId > 0 && !userHasPermission("Can Edit Invoice")) 
            || (invoice.invoiceId === 0 && !userHasPermission("Can create invoices")) 
            || (invoice.invoicePDF?.length > 0 && !userHasPermission("Can Edit PDFd Invoices")) 
            || isPageDataLoading
            || claim.status.toLocaleLowerCase() === 'closed'
            || invoice.isDeleted
            || isFeeScheduleListLoading; 

        const isTE = invoice.isTandE || invoice.invoiceFees?.some(x => x.feeName == "T&E"); 
        const user = helpers.getUserInfo(); 
        const isUserAdjuster = user.id === claim.adjuster?.id; 

        const now = new Date(); 
        const dataDate = new Date(connectAdjusterFees.dataDate || ''); 
        const adjusterFeeAgeHours = Math.abs(now.getTime() - dataDate.getTime()) / 3600000;         
        const salesTaxSuffix = invoice.isTaxExempt ? '  (Tax Exempt)' : invoice.taxRate === 0 ? '  (No Rate)' : ''; 

        let buttonActions: models.ButtonAction[] = [
            {
                name: 'Edit', 
                callBack: (e) => this.loadFeeForEdit(e)
            }, 
            {
                name: 'Delete', 
                callBack: (e) => this.handleDeleteInvoiceFee(e)
            }, 
        ]

        if( userHasPermission(USER_PERMISSIONS.CAN_EDIT_INVOICE_FEE_TAX_STATUS) && !invoice.isTaxExempt && invoice.taxRate > 0) {
            buttonActions = buttonActions.concat({
                name: 'Toggle Taxable', 
                callBack: (e) => this.toggleTaxable(e)
            });             
        }

        const stylePercent = (f: models.InvoiceFee) => {
            const zeroPercentOKList: string[] = ['admin', 'sales tax']; 
            if (f.adjusterPercent === 0 && !zeroPercentOKList.includes(f.feeName.toLocaleLowerCase())) {
                return {backgroundColor: "red", color: "white", fontWeight: "bold"}; 
            }
            else
                return {};
        }

        const styleTaxFlag = (f: models.InvoiceFee) => {
            const isFeeTaxExemept = this.props.feesResponse.resourceList.find(x => x.feeName.toLowerCase() === f.feeName.toLowerCase())?.isTaxExempt; 
            if(!invoice.isTaxExempt && invoice.taxRate > 0 && f.isTaxable !== !isFeeTaxExemept) {
                return {backgroundColor: "yellow"}; 
            }
            else 
                return {}; 
        }
        const hideSalesTaxRow = (f: models.InvoiceFee) => {            
            if(f.feeName.toLocaleLowerCase() === 'sales tax') {
                return {display: "none"}; 
            }
            else 
                return {}; 
        }

        return (
            <>
            <FeeScheduleSuggestionModal
                showModal={showSuggestionModal}
                suggestions={feeScheduleSuggestions}
                closeModal={this.toggleSuggestionModal}
                callback={(e) => this.suggestionCallBack(e)}
            />
            <Modal 
                centered={true} 
                fullscreen={true} 
                onHide={this.resetFormStateAndClose}                 
                scrollable={true} 
                show={showModal}                 
                >
                <Modal.Header closeButton>
                    <Modal.Title>Invoice Details
                    {isPageDataLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{"overflowY": "auto"}}>
                    <Form 
                        className="claim-modal" 
                        name="inv-form"
                        noValidate={true}
                        onSubmit={(e) => this.submitForm(e.target)}
                        validated={validated}
                    >
                        <Row>
                            <CardGroup>
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Billing Info</Card.Title>
                                    <CardBody>
                                        <Row>                                                                                        
                                            <Col md={3} lg={3} className="card-label">Company:</Col>
                                            <Col md={3} lg={3}>{claim?.insuranceCompany}</Col>
                                            <Col md={3} lg={3} className="card-label">Date:</Col>
                                            <Col md={3} lg={3}>{dateFormat(invoice.invoiceDate, "paddedShortDate")}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={3} lg={3} className="card-label">Insured City:</Col>
                                            <Col md={3} lg={3}>{cityState}</Col>
                                            <Col md={3} lg={3} className="card-label">Invoice #:</Col>
                                            <Col md={3} lg={3}>{invoice.invoiceNumber}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={3} lg={3} className="card-label">Claim #:</Col>
                                            <Col md={3} lg={3}>{claim?.insClaimNumber || ''}</Col>
                                            <Col md={3} lg={3} className="card-label">TIN #:</Col>
                                            <Col md={3} lg={3}>{constants.COMPASS_TIN}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={3} lg={3} className="card-label" >Field Adjuster:</Col>
                                            <Col md={3} lg={3} style={{fontWeight: "bold"}}>{invoice.adjuster || ''}</Col>
                                            <Col md={3} lg={3} className="card-label">Company Adjuster:</Col>
                                            <Col md={3} lg={3}>{claim?.examiner?.name || ''}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={3} lg={3} className="card-label">Cat Code:</Col>
                                            <Col md={3} lg={3}>{claim?.catCode || ''}</Col>
                                            <Col md={3} lg={3} className="card-label">Supplemental:</Col>
                                            <Col md={3} lg={3}>{invoice.isSupp ? 'True' : 'False'}</Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Claim Invoices</Card.Title>
                                    <CardBody>
                                        <InvoiceSummaryTable                                            
                                            invoices={invoices.resourceList}
                                        />
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Row>                        
                        <Row>
                            <CardGroup>
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Service Fee</Card.Title>
                                    <Card.Body>
                                        <Row sm={12} className="service-fee-card">
                                            <Col sm={{span:6}}>
                                                <Form.Group as={Row} controlId="grossAmt" className="service-fee-card-field">
                                                    <Col sm={{span:4}}>
                                                        <Form.Label>Gross Loss</Form.Label>                                                                                                    
                                                    </Col>
                                                    <Col sm={{span:7}} style={{paddingLeft: ".5em"}}>
                                                        <InputGroup>
                                                            <InputGroup.Text>$</InputGroup.Text>
                                                            <Form.Control 
                                                                disabled={disableAllEdit}
                                                                type="number"                                                            
                                                                placeholder="0.00"                                                             
                                                                value={invoice.grossLoss || ''}
                                                                onChange={(e) => this.setState({invoice: {...invoice, grossLoss: parseFloat(e.target.value || "0")}})}
                                                                onBlur={(e) => this.setState({serviceFee: {...serviceFee, comment: 'Gross Loss $' + parseFloat(e.target.value || "0").toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}})}
                                                            />                                                        
                                                            <Form.Control.Feedback type="invalid">Gross Loss Required</Form.Control.Feedback>
                                                        </InputGroup>                                                                                                        
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={{span:2}}>
                                                <Form.Group className="service-fee-card-field">
                                                    <Form.Check                                                        
                                                        name="isTandE"
                                                        disabled={disableAllEdit}
                                                        type="checkbox"
                                                        id="chkTandEClaim"
                                                        checked={invoice.isTandE || (invoice.hours || 0) > 0 || false}
                                                        label="Is T&E"                                                         
                                                        onChange={() => this.toggleTE()}                             
                                                    /> 
                                                </Form.Group>
                                            </Col>
                                            <Col sm={{span:4}}>
                                                <Form.Group controlId="teQty" className="service-fee-card-field">
                                                    <Form.Label style={{textAlign: "right"}} as={Col}>T&E Qty</Form.Label>
                                                    <Form.Control                                                     
                                                        disabled={!invoice.isTandE || disableAllEdit}
                                                        type="number" 
                                                        placeholder="Qty..." 
                                                        value={invoice.hours || ''}      
                                                        required={isTE}
                                                        onChange={(e) => this.setState({invoice: {...this.state.invoice, hours: parseFloat(e.target.value)}})} 
                                                        style={{maxWidth: "7em"}}
                                                    />   
                                                    <Form.Control.Feedback type="invalid">Enter T&E Qty!</Form.Control.Feedback>                                                        
                                                </Form.Group>
                                            </Col>
                                        </Row>                                                                              
                                        <Row sm={12} className="service-fee-card">
                                            <Col sm={{span: 8}}>
                                                <Form.Group as={Row} controlId="feeSchedule" className="service-fee-card-field">
                                                    <Col sm={{span: 3}}>
                                                        <Form.Label>Schedule</Form.Label>
                                                    </Col>
                                                    <Col sm={{span:5}} style={{paddingLeft: ".5em"}}>
                                                        <Form.Select name="feeSchedule" 
                                                            disabled={disableAllEdit}
                                                            value={invoice.feeSchedule || ""} 
                                                            onChange={(e) => this.setState({feeScheduleId: this.props.feeScheduleList.resourceList.find(x => x.scheduleName === e.target.value)?.feeScheduleId || 0, invoice: {...invoice, feeSchedule: e.target.value}})}                                                        
                                                            >
                                                                {<option key={-1}>Choose...</option>}                                        
                                                                {feeScheduleList.totalCount > 0 && feeScheduleList.resourceList.filter(x => x.isActive).map((f: models.FeeSchedule, i: number) => ( 
                                                                    <option key={i} value={f.scheduleName} >{f.scheduleName}</option>
                                                                ))}
                                                        </Form.Select>
                                                    </Col>
                                                    <Col className="btn-schedules">
                                                        {!isSuggestionsLoading &&
                                                        <Button 
                                                            disabled={disableAllEdit}
                                                            onClick={serviceFeeMode === 'edit' ? this.handleSaveUpdatedFee : feeScheduleId !== 0 ? this.handleApplySchedule : this.getSuggestions}                                                            
                                                            style={{marginLeft: "1em"}}
                                                        >{serviceFeeMode === 'edit' ? 'SAVE' : feeScheduleId !== 0 ? "APPLY" :  "SUGGEST"}</Button>
                                                        }
                                                        {isSuggestionsLoading &&
                                                            <Spinner animation="border" role="status" variant="primary" size="sm"/>
                                                        }                                                    
                                                    </Col>
                                                </Form.Group> 
                                            </Col>
                                            <Col sm={{span: 4}}>
                                                <Form.Group controlId="taxRate" className="service-fee-card-field">                                                                                                        
                                                    <Form.Label style={{textAlign: "right"}} as={Col}>Tax Rate</Form.Label>
                                                    <InputGroup as={Col}>
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                        <Form.Control 
                                                            disabled={!canEditTaxRate || disableAllEdit || invoice.isTaxExempt}
                                                            type="number" 
                                                            placeholder="Rate..." 
                                                            value={invoice.taxRate || ''} 
                                                            onChange={(e) => this.setState({invoice: {...invoice, taxRate: parseFloat(e.target.value)}})} 
                                                            onBlur={(e) => this.handleTaxRateChange()}
                                                            style={{maxWidth: "7em"}}
                                                        />
                                                    </InputGroup>                                                    
                                                </Form.Group>  
                                            </Col>                                     
                                        </Row>
                                        <Row sm={12} className="service-fee-card">
                                            <Col sm={{span: 8}}>
                                                <Form.Group as={Row} controlId="amt" className="service-fee-card-field">
                                                    <Col sm={{span: 3}}>
                                                        <Form.Label>Amt</Form.Label>
                                                    </Col>
                                                    <Col sm={{span: 5}} style={{paddingLeft: ".5em"}}>
                                                        <InputGroup>
                                                            <InputGroup.Text>$</InputGroup.Text>
                                                            <Form.Control 
                                                                disabled={disableAllEdit || serviceFeeMode !== 'edit'}
                                                                type="number" 
                                                                placeholder="0.00" 
                                                                value={serviceFee.rate || ''} 
                                                                onChange={(e) => this.setState({serviceFee: {...serviceFee, rate: parseFloat(e.target.value)}})} 
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>                                                
                                            </Col>                                            
                                            <Col sm={{span: 4}}>
                                                <Form.Group controlId="adjusterPercent" className="service-fee-card-field">                                                    
                                                    <Form.Label style={{textAlign: "right"}} as={Col}>Adj %</Form.Label>                                                    
                                                    <Form.Control 
                                                        disabled={disableAllEdit || isUserAdjuster || serviceFeeMode !== 'edit'}
                                                        type="number" 
                                                        placeholder="%..." 
                                                        value={serviceFee.adjusterPercent}                                                         
                                                        onChange={(e) => this.setState({
                                                            serviceFee: {
                                                                ...serviceFee, 
                                                                adjusterPercent: parseInt(e.target.value)
                                                            }
                                                        })}
                                                        style={{maxWidth: "7em"}}
                                                    />
                                                    <Form.Control.Feedback type="invalid">Enter Service Fee %!</Form.Control.Feedback>
                                                </Form.Group> 
                                            </Col>
                                        </Row>
                                        <Row sm={12} className="vertical-center">     
                                            <Col>
                                                <Form.Group controlId="comment" className="service-fee-card-field">
                                                    <Col sm={{span: 2}}>
                                                        <Form.Label>Comment</Form.Label>
                                                    </Col>
                                                    <Col sm={{span: 10}}>
                                                        <Form.Control                                                     
                                                            as="textarea" 
                                                            disabled={disableAllEdit}
                                                            value={serviceFee.comment || ""} 
                                                            onChange={(e) => this.setState({serviceFee: {...serviceFee, comment: e.target.value}})} 
                                                        />
                                                    </Col>
                                                </Form.Group>    
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Additional Fees</Card.Title>
                                    <Card.Body>
                                        <Row sm={9} className="vertical-center">
                                            <Form.Group as={Col} controlId="fee" className="input-field-container2">
                                                <Col sm={{span: 4}}>
                                                    <Form.Label>Fee</Form.Label>
                                                </Col>
                                                <Col sm={{span: 7}}>
                                                    <Form.Select name="fee" 
                                                        disabled={disableAllEdit || feeEditMode}
                                                        value={fee.feeName} 
                                                        onChange={(e) => this.handleFeeChange(e)} 
                                                        >
                                                            {<option key={-1}>Choose...</option>}
                                                            {feesResponse.totalCount > 0 && feesResponse.resourceList.filter(x => !['service', 'sales tax'].includes(x.feeName.toLocaleLowerCase()) && x.isActive).map((f: models.Fee, i: number) => (                                                                 
                                                                <option key={i} value={f.feeName} >{f.feeName}</option>
                                                            ))}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                            <Col>
                                                <Form.Group as={Col} controlId="qty" className="input-field-container2">
                                                    <Col sm={{span: 3}}>
                                                        <Form.Label>Qty</Form.Label>
                                                    </Col>
                                                    <Col sm={{span: 3}}>
                                                        <Form.Control 
                                                            disabled={disableAllEdit}
                                                            type="number"                                                             
                                                            placeholder="#..." 
                                                            value={fee.quantity || ''} 
                                                            onChange={(e) => this.setState({fee: {...fee, quantity: parseFloat(e.target.value)}})} 
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row sm={9} className="vertical-center">
                                            <Form.Group as={Col} controlId="amt" className="input-field-container2">
                                                <Col sm={{span: 4}}>
                                                    <Form.Label>Amt</Form.Label>
                                                </Col>
                                                <Col sm={{span: 7}}>
                                                    <InputGroup>
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control 
                                                            disabled={disableAllEdit}
                                                            type="number" 
                                                            placeholder="0.00" 
                                                            value={fee.rate || ''} 
                                                            onChange={(e) => this.setState({fee: {...fee, rate: parseFloat(e.target.value)}})} 
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adjusterPercent" className="input-field-container2">
                                                <Col sm={{span: 3}}>
                                                    <Form.Label>Adj %</Form.Label>
                                                </Col>
                                                <Col sm={{span: 3}}>
                                                    <Form.Control 
                                                        disabled={disableAllEdit || isUserAdjuster || fee.feeName.toLocaleLowerCase() === "admin" || (fee.adjusters && fee.adjusters.length >= 2)}
                                                        type="number" 
                                                        placeholder="%..." 
                                                        value={fee.adjusterPercent}                                                         
                                                        onChange={(e) => this.setState({
                                                            fee: {
                                                                ...fee, 
                                                                adjusterPercent: parseInt(e.target.value)
                                                            }
                                                        })} 
                                                    />
                                                </Col>
                                                {(_.isEmpty(connectAdjusterFees.dataDate) || adjusterFeeAgeHours > 4) &&
                                                    <Col>
                                                        <OverlayTrigger                                                                                                                 
                                                            key="overlayTrigger"
                                                            placement="top"                                                            
                                                            overlay={
                                                                <Tooltip className="my-tooltip" id="toolTip">Adjuster data is stale, sync profile...</Tooltip>
                                                            }
                                                        >
                                                            <img className="info-icon" src={redInfoIcon}/>
                                                        </OverlayTrigger>
                                                    </Col>
                                                }                    
                                            </Form.Group>                                    
                                        </Row>
                                        <Row sm={9} className="vertical-center">
                                            <Form.Group as={Col} controlId="comment" className="input-field-container2">
                                                <Col sm={{span: 4}}>
                                                    <Form.Label>Comment</Form.Label>
                                                </Col>
                                                <Col sm={{span: 7}}>
                                                    <Form.Control 
                                                        as="textarea" 
                                                        disabled={disableAllEdit}
                                                        value={fee.comment || ''} 
                                                        onChange={(e) => this.setState({fee: {...fee, comment: e.target.value}})} 
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Col>
                                                <Button
                                                    disabled={disableAllEdit}
                                                    onClick={this.handleAdditionalFee}
                                                >{(fee.invoiceFeeId === 0 && !feeEditMode) ? "ADD TO INVOICE" : "SAVE FEE"}</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>                                
                            </CardGroup>                                        
                        </Row>
                        <Row>
                            <CardGroup>
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Invoice Fees</Card.Title>
                                    <CardBody>
                                        <Table striped>
                                            <thead>
                                                <tr>
                                                    <th>Fee</th>
                                                    <th>Taxable</th>
                                                    <th>Qty</th>
                                                    <th>Rate</th>
                                                    <th>Fee Total</th>
                                                    <th>Adjuster %</th>                                                    
                                                    <th>Comment</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!(invoice.invoiceFees === undefined) && invoice.invoiceFees?.map((f: models.InvoiceFee, i: number) => (                                                                                                    
                                                    <tr key={i} style={hideSalesTaxRow(f)}>                                                        
                                                        <td>{f.feeName}</td>
                                                        <td style={styleTaxFlag(f)} >{f.isTaxable ? "Yes" : "No"}</td>
                                                        <td>{f.quantity}</td>
                                                        <td><NumberFormat value={f.feeName.toLocaleLowerCase() === 'mileage' ? f.rate.toFixed(3) : f.rate.toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'}  /></td>
                                                        <td><NumberFormat value={f.total.toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'}  /></td>
                                                        <td style={stylePercent(f)} >{f.adjusterPercent}</td>                                                        
                                                        <td>{f.comment}</td>                                                        
                                                        {!disableAllEdit && f.feeName.toLocaleLowerCase() !== 'sales tax' && 
                                                            <td className="btns-right">
                                                                <GearButton                                                                                                                               
                                                                    actions={buttonActions}
                                                                    rowId={i.toString()}
                                                                />
                                                            </td>
                                                        }
                                                        {f.feeName.toLocaleLowerCase() === 'sales tax' && <td/>}
                                                    </tr>
                                                ))}  
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>  
                                <Card className="claim-card" style={{"maxWidth": "30em"}}>
                                    <Card.Title className="inv-card-title">Invoice Summary</Card.Title>
                                    <CardBody style={{"alignContent": "center"}}>
                                        <Row>
                                            <Col sm={6} md={6} lg={6} className="card-label">SUB TOTAL:</Col>
                                            <Col sm={6} md={6} lg={6}><NumberFormat value={invoice.subTotal.toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'}  /></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6} md={6} lg={6} className="card-label">SALES TAX:</Col>
                                            <Col sm={6} md={6} lg={6}><NumberFormat value={invoice.salesTax.toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'}  />{salesTaxSuffix}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6} md={6} lg={6} className="card-label">TOTAL:</Col>
                                            <Col sm={6} md={6} lg={6}><NumberFormat value={invoice.grandTotal.toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'}  /></Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Row>
                        </Form>    
                    </Modal.Body>                
                    <Modal.Footer>
                        <Button 
                            onClick={this.resetFormStateAndClose}
                            style={{"float":"left"}}
                            variant="secondary"
                            >
                            CANCEL
                        </Button>
                        {!disableAllEdit &&
                            <ButtonGroup>
                                <Button                             
                                    disabled={disableAllEdit || disableSave || !userHasPermission("Can approve/finalize invoice")}
                                    form="inv-form"                            
                                    name="btn-save"                             
                                    onClick={(e) => this.submitForm(e)}     
                                    style={{"width": "fit-content"}}
                                    value="savePDF"
                                    variant="primary" 
                                    >
                                    SAVE & PDF
                                </Button>
                                <Button 
                                    disabled={disableAllEdit || disableSave}
                                    form="inv-form"
                                    name="btn-save" 
                                    onClick={(e) => this.submitForm(e)}
                                    value="save"
                                    variant="primary" 
                                    >
                                    SAVE
                                </Button>
                            </ButtonGroup>
                        }
                    </Modal.Footer>                
            </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,
    connectAdjusterFees: state.financials.financials.connectAdjusterFees, 
    draftInvoice: state.claims.claim.draftInvoice,     
    feeScheduleList: state.financials.financials.feeSchedulesResponse,    
    feesResponse: state.financials.financials.feesResponse, 
    invoices: state.claims.claim.invoices,     
    insuredPerson: state.claims.claim.insuredPerson, 
    invoice: state.financials.financials.invoice,  
    isClaimLoading: state.claims.claim.isClaimLoading, 
    isConnectAdjusterFeesLoading: state.financials.financials.isConnectAdjusterFeesLoading,
    isDraftInvoiceLoading: state.claims.claim.isDraftInvoiceLoading,  
    isFeeScheduleListLoading: state.financials.financials.isFeeScheduleListLoading,
    isInvoicesLoading: state.claims.claim.isInvoicesLoading, 
    isInvoiceLoading: state.financials.financials.isInvoiceLoading,
    lastUserAction: state.user.actions.lastUserAction,
    lastInvCreatedId: state.user.events.lastInvCreatedId, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            addClaimInvoice: claimsActions.addClaimInvoice,
            addFee: financialsActions.addFee, 
            buildPDF: financialsActions.buildPDF, 
            clearClaimSingle: claimsActions.clearClaimSingle,             
            clearInvoice: financialsActions.clearInvoice,
            clearSelectedInvoices: financialsActions.clearSelectedInvoices,
            getFeeSchedules: financialsActions.requestFeeSchedulesByCompany, 
            getFeesList: financialsActions.requestFeesList, 
            patchInvoice: financialsActions.patchInvioce, 
            replaceInvoice: financialsActions.replaceInvoice,             
            requestClaimSingle: claimsActions.requestClaimSingle, 
            requestConnectAdjusterFees: financialsActions.requestAdjusterFees, 
            requestInvoice: financialsActions.requestInvoice, 
            requestInvoiceDraft: claimsActions.requestInvoiceDraft,
            setAppToast: userActions.setAppToast, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Invoice);
