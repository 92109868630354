import * as React from 'react'; 
import Chart from 'react-apexcharts'; 
import { Card } from 'react-bootstrap'; 
import * as models from '../../../models'; 
import _ from 'lodash'; 

interface Props {
    payrollByMonth: models.PayrollByMonth[]
}

interface State {
    series: any;  
    options: any; 
}

class PayrollByMonth extends React.PureComponent<Props, State> {
    public state = {
        options: {},
        series: []        
    }

    public componentDidMount = () => {
        if(this.props.payrollByMonth.length > 0)
            this.loadDataToChart();    
    }       
    
    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if(!_.isEqual(prevProps.payrollByMonth, this.props.payrollByMonth) && this.props.payrollByMonth.length > 0){
            this.loadDataToChart(); 
        }        
    }

    loadDataToChart = () => {
        const { payrollByMonth } = this.props;             
        const months: string[] = payrollByMonth.map(x => {return x.month}); 
        const payrollAmt: number[] =  payrollByMonth.map(x => {return x.amount}); 
        const advancedAmt: number[] =  payrollByMonth.map(x => {return x.advancedAmount});

        this.setState({
            options: {
                title: {
                    text: "Monthly Payroll Activity",
                    align: 'center',                    
                },
                labels: months,
                stroke: {
                    width: [0, 4]
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                chart: {                    
                    zoom: {
                        enabled: false,
                    }
                },
                xaxis: {
                    labels: {
                        rotateAlways: true, 
                        rotate: -45
                    }
                }, 
                yaxis: { 
                    labels: {
                        formatter: function (value:number) {
                            return "$" + value; 
                        }
                    } 
                },                
            }, 
            series: [
                {
                    name: 'Payroll Amt',
                    type: 'column',
                    data: payrollAmt
                }, 
                {
                    name: 'Advanced Amt',
                    type: 'line',
                    data: advancedAmt
                }
            ],
        }); 
    }

    getMonthRange = (monthName: string, series: number) => {
        const year = monthName.split("-")[0]; 
        const datePair = series === 0 ? "received" : "closed"; 
        switch (monthName.split("-")[1].toLowerCase()) {
            case "jan": 
                return {[datePair+"DateFrom"]: year + "-01-01", [datePair+"DateTo"]: year + "-01-31"}; 
            case "feb":
                return {[datePair+"DateFrom"]: year + "-02-01", [datePair+"DateTo"]: year + "-02-28"}; 
            case "mar":
                return {[datePair+"DateFrom"]: year + "-03-01", [datePair+"DateTo"]: year + "-03-31"}; 
            case "apr":
                return {[datePair+"DateFrom"]: year + "-04-01", [datePair+"DateTo"]: year + "-04-30"}; 
            case "may":
                return {[datePair+"DateFrom"]: year + "-05-01", [datePair+"DateTo"]: year + "-05-31"}; 
            case "jun":
                return {[datePair+"DateFrom"]: year + "-06-01", [datePair+"DateTo"]: year + "-06-30"}; 
            case "jul":
                return {[datePair+"DateFrom"]: year + "-07-01", [datePair+"DateTo"]: year + "-07-31"}; 
            case "aug":
                return {[datePair+"DateFrom"]: year + "-08-01", [datePair+"DateTo"]: year + "-08-31"}; 
            case "sept":
                return {[datePair+"DateFrom"]: year + "-09-01", [datePair+"DateTo"]: year + "-09-30"}; 
            case "oct":
                return {[datePair+"DateFrom"]: year + "-10-01", [datePair+"DateTo"]: year + "-10-31"}; 
            case "nov":
                return {[datePair+"DateFrom"]: year + "-11-01", [datePair+"DateTo"]: year + "-11-30"}; 
            case "dec":
                return {[datePair+"DateFrom"]: year + "-12-01", [datePair+"DateTo"]: year + "-12-31"}; 
        }
    }

    render() {
        return (
            <Card>
                <Chart 
                    options={this.state.options} 
                    series={this.state.series} 
                    type="line" 
                    height={410} 
                />
            </Card>     
        );
    }
}
   
export default PayrollByMonth; 