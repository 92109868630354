import { request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 


export const addPayroll = async (addPayroll: models.AddPayroll) => {    
    const response = await request ({
        method: 'POST',
        url: `/payroll`,
        data: addPayroll,
    }); 
    return response; 
}

export const deletePayroll = async (payrollId: number, reason: string) => {
    var qStr = utils.helpers.encodeQueryString({reason: reason});  
    const response = await request ({
    method: 'DELETE', 
    url: `/payroll/${payrollId}?${qStr}`
    });
    return response; 
}

export const getAdjusterFees = async (search: models.AdjusterFeeRequest) => {
    var qStr = utils.helpers.encodeQueryString(search);  
    
    const response = await request({
      method: 'GET',
      url: `/invoices/adjusterFees?${qStr}`, 
    });
    return response; 
}; 

export const getAdjusterFeeList = async (search: models.AdjusterFeeListRequest) => {
    var qStr = utils.helpers.encodeQueryString(search);  
    
    const response = await request({
      method: 'GET',
      url: `/payroll/adjusterFees?${qStr}`, 
    });
    return response; 
}; 

export const getAdjusterFeeListCSV = async (search: models.AdjusterFeeListRequest) => {
    var qStr = utils.helpers.encodeQueryString(search);  
    
    const response = await request({
      method: 'GET',
      url: `/payroll/adjusterFees/export?${qStr}`, 
    });
    if (response.isSuccess) {
        const responseData = await utils.filehelpers.readAsDataURL(new Blob([response.data], {type: "application/csv"}));
        try {
            utils.filehelpers.downloadFile(response);

        } catch (err) {
            return {
                error: err,
              };
        }     
    return true; 
    }
    else {
        console.log(response); 
    }
}; 

export const getPayrollDetail = async (payrollId: number) => {
    const response = await request ({
        method: 'GET',
        url: `/payroll/${payrollId}`,       
    }); 
    return response; 
}

export const getPayrollFileList = async (payrollId: number) => {
    const response = await request ({
        method: 'GET',
        url: `/payroll/${payrollId}/files`,       
    }); 
    return response; 
}

export const getPayrollFile = async (payrollId: number, fileName: string) => {
    const newFileName = fileName.replace("/", "%252F"); 
    const response = await request ({
        method: 'GET',
        url: `/payroll/${payrollId}/files/${newFileName}`, 
        responseType: 'blob',
    }); 
    
    if (response.isSuccess) {
        try {
            utils.filehelpers.downloadFile(response);
        } catch (err) {
            return {
                error: err,
                };
        }     
    return true; 
    }
}

export const getPayrollList = async (payrollListRequest: models.PayrollListRequest) => {
    var qStr = utils.helpers.encodeQueryString(payrollListRequest);     

    const response = await request({
        method: 'GET',
        url: `/payroll?${qStr}`, 
    });
    return response; 
}

export const getPayrollCSV = async (payrollRequest: models.PayrollListRequest) => {
    var qStr = utils.helpers.encodeQueryString(payrollRequest);     
    const response = await request ({
        method: 'GET',
        url: `/payroll/export?${qStr}`, 
    });     
    if (response.isSuccess) {
        const responseData = await utils.filehelpers.readAsDataURL(new Blob([response.data], {type: "application/csv"}));
        try {
            utils.filehelpers.downloadFile(response);

        } catch (err) {
            return {
                error: err,
              };
        }     
    return true; 
    }
    else {
        console.log(response); 
    }
}

export const getPayrollStatus = async (payrollId: number) => {
    const response = await request ({
        method: 'GET',
        url: `/payroll/${payrollId}/status`,       
    }); 
    return response; 
}
  
export const getPayrollZipFile = async (payrollId: number) => {  
    const response = await request ({
        method: 'GET',
        url: `/payroll/${payrollId}/zipFile`, 
        responseType: 'blob',
    }); 

    if (response.isSuccess) {
        try {
            utils.filehelpers.downloadFile(response);
        } catch (err) {
            return {
                error: err,
                };
        }     
    return true; 
    }
}  

export const rebuildPayrollFiles = async (payrollId: number) => {
    const response = await request ({
        method: 'POST',
        url: `/payroll/${payrollId}/files`
    }); 
    return response; 
}