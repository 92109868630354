import Admin from './admin';
import ClaimDetails from './claim-details';
import ClaimsList from './claims-list/';
import CompanyDetail from './admin/CompanyDetail'; 
import ContactDetail from './admin/ContactDetail';
import Dashboard from './dashboards/claims'; 
import Financials from './financials';
import NotFound from './app/NotFound';
import Payroll from './payroll'; 
import Reports from './reports';
import Security from './security';
import Queues from './queues';
import Unauthorized from './app/Unauthorized';

export default {
    Admin,
    ClaimDetails,  
    ClaimsList,
    Queues,
    CompanyDetail, 
    ContactDetail,
    Dashboard,
    Financials,
    NotFound, 
    Payroll, 
    Reports,  
    Security,
    Unauthorized
};