import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';

export const startPoll = (payrollId: number) => 
    action(constants.POLL_START, { payrollId }); 
  
export const stopPoll = () => 
    action(constants.POLL_STOP);
export const pollError = () => 
    action(constants.POLL_ERROR); 

export const requestPayrollStatus = (payrollId: number) => 
    action(constants.REQUEST_PAYROLL_STATUS, { payrollId }); 
export const receivePayrollStatus = (response: models.PayrollStatusResponse) => 
    action(constants.RECEIVE_PAYROLL_STATUS, { response }); 