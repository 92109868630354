import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Accordion, Card, Col, Modal, Row, Spinner } from 'react-bootstrap'; 
import { PayrollAdjuster } from 'models';
import NumberFormat from 'react-number-format';
import dateFormat from 'dateformat';
import PayrollFeeTable from './PayrollFeeTable';
import SingleActionButton from 'components/common/SingleActionButton';
import downLoadIcon from '../../assets/images/Blue/download.svg'; 
import trashIcon from '../../assets/images/Blue/trash.svg'; 
import { payrollActions } from 'store/payroll';
import GetReasonModal from 'components/common/GetReasonModal';

interface Props extends PropsFromRedux {
    showModal: boolean;
    closeModal: () => void;
}

interface State {
    showGetReasonModal: boolean; 
}

class PayrollReview extends React.Component<Props, State> {
    public state = {
        showGetReasonModal: false,  
    };

    public getAdjusterFeeCount = (adjusterId: number) => {        
        return this.props.payrollDetail.adjusters.find(a => a.adjusterId === adjusterId)?.fees.length || 0; 
    }
    public getAdjusterTotal = (adjusterId: number) => {
        return this.props.payrollDetail.adjusters.find(a => a.adjusterId === adjusterId)?.fees.reduce((a, c) => a = a + c.amount, 0) || 0; 
    }
    public getAdjusterAdvTotal = (adjusterId: number) => {
        return this.props.payrollDetail.adjusters.find(a => a.adjusterId === adjusterId)?.fees?.filter(x => x.isAdvance).reduce((a, c) => a = a + c.amount, 0) || 0; 
    }
    public handleDownload = () => {
        this.props.dowloadZip(this.props.payrollDetail.id); 
    }
    public toggleGetReasonModal = () => {
        this.setState({showGetReasonModal: !this.state.showGetReasonModal}); 
    }
    public handleDelete = () => {
        this.toggleGetReasonModal(); 
    }
    public deletePayroll = (reason: string) => {
        const payrollId = this.props.payrollDetail.id;                
        this.props.deletePayroll(payrollId, reason);  
    }

    render() {
        const { closeModal, isPayrollLoading, payrollDetail, showModal } = this.props;  
        const { showGetReasonModal } = this.state; 

        return (
            <>
            <GetReasonModal
                handleSubmit={this.deletePayroll}
                showModal={showGetReasonModal}
                closeModal={this.toggleGetReasonModal}
            />
            <Modal fullscreen show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Payroll{isPayrollLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}</Modal.Title>
                </Modal.Header>                                   
                <Modal.Body>
                    <Card className="claim-card">  
                        <Card.Title className="inv-card-title">Payroll Overview
                            <SingleActionButton
                                action="Download"
                                title="Zip"
                                img={downLoadIcon}
                                onClick={this.handleDownload}
                            />
                            <SingleActionButton
                                action="Delete"
                                title="Payroll"
                                img={trashIcon}
                                onClick={this.handleDelete}
                            />
                        </Card.Title>
                        <Row>
                            <Col md={3} lg={3} className="card-label">Payroll Name:</Col>
                            <Col md={3} lg={3} >{payrollDetail.payrollName}</Col>
                            <Col md={3} lg={3} className="card-label">Status:</Col>
                            <Col md={3} lg={3} >{payrollDetail.status}</Col>                            
                        </Row>
                        <Row>
                            <Col md={3} lg={3} className="card-label">Start Date:</Col>
                            <Col md={3} lg={3} >{dateFormat(payrollDetail.startDate, "paddedShortDate")}</Col>                            
                            <Col md={3} lg={3} className="card-label">Storage Folder:</Col>
                            <Col md={3} lg={3} >{payrollDetail.storageFolder}</Col>                            
                        </Row>
                        <Row>
                            <Col md={3} lg={3} className="card-label">End Date:</Col>
                            <Col md={3} lg={3} >{dateFormat(payrollDetail.endDate, "paddedShortDate")}</Col>                            
                            <Col md={3} lg={3} className="card-label">Adjusters:</Col>
                            <Col md={3} lg={3} >{payrollDetail.adjusters.length}</Col>                            
                        </Row>
                        <Row>
                            <Col md={3} lg={3} className="card-label">Comments:</Col>
                            <Col md={3} lg={3} >{payrollDetail.comments}</Col>
                            <Col md={3} lg={3} className="card-label">Total:</Col>
                            <Col md={3} lg={3} ><NumberFormat value={payrollDetail.total.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix='$'/></Col>
                        </Row>
                    </Card>
                    <Card className="claim-card">  
                        <Card.Title className="inv-card-title">Payroll Details</Card.Title>
                        <Card.Body>
                            <Accordion className="inv-acc-card" alwaysOpen>
                                {payrollDetail.adjusters && payrollDetail.adjusters.map((x: PayrollAdjuster, i:number) => {
                                    return (
                                        <Accordion.Item key={i} eventKey={x.adjuster}>
                                            <Accordion.Header className="inv-acc-hdr">
                                                <Col>Adjuster: {x.adjuster}</Col>
                                                <Col>Fees: <NumberFormat value={this.getAdjusterFeeCount(x.adjusterId)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                                                <Col>Advance Amt: <NumberFormat value={this.getAdjusterAdvTotal(x.adjusterId).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                                <Col>Total: <NumberFormat value={this.getAdjusterTotal(x.adjusterId).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <PayrollFeeTable
                                                    fees={x.fees}
                                                />  
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ); 
                                })}
                            </Accordion>      
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
        ); 
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    payrollDetail: state.payroll.payroll.payrollDetail,
    isPayrollLoading: state.payroll.payroll.isPayrollLoading, 
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {   
            deletePayroll: payrollActions.deletePayroll, 
            dowloadZip: payrollActions.requestPayrollZipFile, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(PayrollReview); 