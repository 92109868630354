import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';

export const addPayroll = (request: models.AddPayroll) =>
    action(constants.ADD_PAYROLL, { request }); 
export const addPayrollComplete = (response: number) =>
    action(constants.ADD_PAYROLL_COMPLETE, { response }); 
    
export const clearAdjusterFeesList = () =>
    action(constants.CLEAR_ADJUSTER_FEES_LIST); 

export const deletePayroll =(payrollId: number, reason: string) =>
    action(constants.DELETE_PAYROLL, { payrollId, reason }); 

export const rebuildPayrollFiles = (payrollId: number) =>
    action(constants.REBUILD_PAYROLL_FILES, { payrollId }); 

export const requestAdjusterFees = (request: models.AdjusterFeeRequest) =>
    action(constants.REQUEST_ADJUSTER_FEES, { request }); 
export const receiveAdjusterFees = (response: models.AdjusterFeeResponse) =>
    action(constants.RECEIVE_ADJUSTER_FEES, { response }); 

export const requestAdjusterFeesList = (request: models.AdjusterFeeListRequest) =>
    action(constants.REQUEST_ADJUSTER_FEES_LIST, { request }); 
export const receiveAdjusterFeesList = (response: models.AdjusterFeeResponse) =>
    action(constants.RECEIVE_ADJUSTER_FEES_LIST, { response }); 

export const requestAdjusterFeesListCSV = (request: models.AdjusterFeeListRequest) =>
    action(constants.REQUEST_ADJUSTER_FEES_LIST_CSV, { request }); 
export const receiveAdjusterFeesListCSV = () =>
    action(constants.RECEIVE_ADJUSTER_FEES_LIST_CSV); 

export const requestPayroll = (payrollId: number) =>
    action(constants.REQUEST_PAYROLL, { payrollId });     
export const receivePayroll = (response: models.PayrollDetail) =>
    action(constants.RECEIVE_PAYROLL, { response });

export const requestPayrollCSV = (request: models.PayrollListRequest) =>
    action(constants.REQUEST_PAYROLL_CSV, { request });     
export const receivePayrollCSV = () =>
    action(constants.RECEIVE_PAYROLL_CSV);         

export const requestPayrollList = (request: models.PayrollListRequest) =>
    action(constants.REQUEST_PAYROLL_LIST, { request }); 
export const receivePayrollList = (response: models.PayrollListResponse) => 
    action(constants.RECEIVE_PAYROLL_LIST, { response }); 

export const requestPayrollZipFile = (payrollId: number) =>
    action(constants.REQUSET_PAYROLL_ZIPFILE, { payrollId }); 

export const receivePayrollZipFile = () =>
    action(constants.RECEIVE_PAYROLL_ZIPFILE); 
