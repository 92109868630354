import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import GearButton from 'components/common/GearButton';
import NumberFormat from 'react-number-format';
import { Table, Card, Col, Spinner } from 'react-bootstrap';
import * as models from '../../models';
import dateFormat from 'dateformat';
import SingleActionButton from 'components/common/SingleActionButton'; 
import downloadIcon from '../../assets/images/Blue/download.svg';
import refreshIcon from '../../assets/images/icons8-refresh.svg'; 
import GetReasonModal from 'components/common/GetReasonModal';
import PayrollReviewModal from './PayrollReviewModal';
import _ from 'lodash'; 
import { payrollActions } from 'store/payroll';
import { USER_ACTIONS } from 'store/enums';
interface Props extends PropsFromRedux {
}

interface State {
    payrollId: number; 
    showDetailsModal: boolean; 
    showGetReasonModal: boolean; 
}
class PayrollList extends React.Component<Props, State> {
    public state = {
        payrollId: 0, 
        showDetailsModal: false, 
        showGetReasonModal: false, 
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!_.isEqual(prevProps.lastUserAction, this.props.lastUserAction) && this.props.lastUserAction === USER_ACTIONS.PAYROLL_DELETING_SUCCESS) {
            this.setState({payrollId: 0}); 
            this.toggleGetReasonModal();             
        }        
    }

    public exportPayrolls = async () => {
        this.props.requestPayrollCSV(this.props.payrollListRequest);
    }

    public toggleDetailsModal = () => {
        this.setState({showDetailsModal: !this.state.showDetailsModal}); 
    }
    public toggleGetReasonModal = () => {
        this.setState({showGetReasonModal: !this.state.showGetReasonModal}); 
    }

    // gear button actions
    public deletePayroll = (reason: string) => {
        const payrollId = this.state.payrollId;                
        this.props.deletePayroll(payrollId, reason);  
    }

    public  handleDeletePayroll = (payrollId: number) => {
        this.setState({payrollId: payrollId}); 
        this.toggleGetReasonModal(); 
    }

    public handleDownloadZip = (payrollId: number) => {
        this.props.requestPayrollZipFile(payrollId); 
    }

    public handleViewPayroll = (payrollId: number) => {
        this.props.requestPayroll(payrollId);
        this.toggleDetailsModal(); 
    }
    public handleRebuildFiles = (payrollId: number) => {
        this.props.requestPayroll(payrollId);
    }

    public handleRefreshList = () => {
        this.props.requestPayrollList(this.props.payrollListRequest); 
    }

    public render() {
        const { isPayrollCSVDownloading, isPayrollListLoading, isZipDownloading, payrollListResponse } = this.props; 
        const { showDetailsModal, showGetReasonModal } = this.state; 

        const sumAdvanceTotal = payrollListResponse.resourceList.reduce((a, p) => a = a + p.advanceTotal, 0);         
        const sumPayrolLTotal = payrollListResponse.resourceList.reduce((a, p) => a = a + p.payrollTotal, 0); 
        
        let buttonActions: models.ButtonAction[] = new Array<models.ButtonAction>(); 
        buttonActions = buttonActions.concat({
            name: 'Delete', 
            callBack: (e) => this.handleDeletePayroll(parseInt(e))
        }); 
        buttonActions = buttonActions.concat({
            name: 'Download Zip', 
            callBack: (e) => this.handleDownloadZip(parseInt(e))
        }); 
        buttonActions = buttonActions.concat({
            name: 'Rebuild Files', 
            callBack: (e) => this.handleRebuildFiles(parseInt(e))
        }); 
        buttonActions = buttonActions.concat({
            name: 'View Details', 
            callBack: (e) => this.handleViewPayroll(parseInt(e))
        }); 

        return (
            <>
            <GetReasonModal
                handleSubmit={this.deletePayroll}
                showModal={showGetReasonModal}
                closeModal={this.toggleGetReasonModal}
            />
            <PayrollReviewModal
                showModal={showDetailsModal}
                closeModal={this.toggleDetailsModal}
            />
            <Card className="claim-card">                
                {false && 
                    <span>Downloading...</span> 
                }
                <Card.Title>Payrolls
                    {isZipDownloading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}                    
                    <SingleActionButton
                            action="Export"
                            title="Payrolls"
                            img={downloadIcon}
                            onClick={this.exportPayrolls}
                            disabled={isPayrollCSVDownloading}
                        />
                    <SingleActionButton
                            action="Refresh"
                            title="Payrolls"
                            img={refreshIcon}
                            onClick={this.handleRefreshList}
                            disabled={isPayrollCSVDownloading}
                        />
                    <div className='table-hdr-totals' style={{paddingTop: ".25em"}}>
                        <Col style={{width: "150px", display: "flex", justifyContent: "space-between"}}>
                            Advanced:<NumberFormat value={sumAdvanceTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
                        </Col>
                        <Col style={{width: "150px", display: "flex", justifyContent: "space-between"}}>
                            Total:<NumberFormat value={sumPayrolLTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </Col>
                    </div>
                </Card.Title>   
                {isPayrollListLoading
                    && <span>Loading...</span> 
                }   
                {payrollListResponse?.totalCount == 0 && !isPayrollListLoading &&
                <div>0 results found</div>
                }
                {payrollListResponse?.totalCount > 0 &&
                    <Table striped>
                        <thead>
                        <tr className="claim-table-header" >                                
                                <th>Payroll Name</th>
                                <th>Run Date</th>
                                <th>Comments</th>
                                <th>Claims</th>
                                <th>Invoices</th>
                                <th>Adjusters</th>
                                <th>Advance Total</th>
                                <th>Payroll Total</th>
                                <th>Status</th>
                                <th>Created By</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {payrollListResponse?.resourceList?.map((p: models.PayrollSummary, i: number) => (
                                <tr key={p.payrollId}>
                                    <td>{p.payrollName}</td>
                                    <td>{p.runDate && dateFormat(p.runDate, 'mm/dd/yyyy')}</td>
                                    <td>{p.comments}</td>
                                    <td>{p.claims}</td>
                                    <td>{p.invoices}</td>
                                    <td>{p.adjusters}</td>
                                    <td><NumberFormat value={p.advanceTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                    <td><NumberFormat value={p.payrollTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                    <td>{p.status}</td>
                                    <td>{p.createdBy}</td>
                                    <td style={{overflow: "visible", display: "flex", justifyContent: "space-between", paddingRight: "0" }}>                                        
                                        <div style={{margin: "auto"}}>
                                            <GearButton
                                                actions={buttonActions || new Array<models.ButtonAction>()}
                                                rowId={p.payrollId.toString()}
                                            />   
                                        </div>                  
                                    </td>
                                </tr>
                            ))}
                        </tbody> 
                    </Table>
                }
            </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    isPayrollCSVDownloading: state.payroll.search.isPayrollCSVDownloading, 
    isPayrollListLoading: state.payroll.search.isPayrollListLoading,  
    isZipDownloading: state.payroll.payroll.isZipDownloading, 
    lastUserAction: state.user.actions.lastUserAction, 
    payrollListRequest: state.payroll.search.payrollListRequest, 
    payrollListResponse: state.payroll.search.payrollListResponse,
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            deletePayroll: payrollActions.deletePayroll, 
            requestAdjusterFeesList: payrollActions.requestAdjusterFeesList, 
            requestPayroll: payrollActions.requestPayroll, 
            requestPayrollList: payrollActions.requestPayrollList,
            requestPayrollZipFile: payrollActions.requestPayrollZipFile, 
            requestPayrollCSV: payrollActions.requestPayrollCSV, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(PayrollList); 