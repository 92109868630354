import { delay, put, call, race, take } from 'redux-saga/effects';
import * as types from './constants';
import * as actions from './actions';
import services from '../../services';
import { now } from 'lodash';

export function* pollPayrollStatus(payrollId: number): any {
    while(true) {
        try {     
            const userRequest = yield call(services.api.payroll.getPayrollStatus, payrollId);
            yield put(actions.receivePayrollStatus(userRequest.data));
            yield delay(1000);   
        }
        catch(err) {            
            let message = ""; 
            if(typeof err === "string"){
                message = err; 
            } else if (err instanceof Error) {
                message = err.message; 
            }
            console.log(message);             
            yield put(actions.stopPoll()); 
        }
    }
}

export default function* pollPayrollWatch(): any {
    while (true) {
        const action = yield take(types.POLL_START); 
        yield race([call(pollPayrollStatus, action.payload.payrollId), take(types.POLL_STOP)]); 
    }
}