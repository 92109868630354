import { fork, put } from 'redux-saga/effects';
import adminSaga from './admin/saga'; 
import claimsSaga from './claims/saga';
import companiesSaga from './companies/saga'; 
import contactsSaga from './contacts/saga'; 
import dashboardSaga from './dashboard/saga'; 
import financialsSaga from './financials/saga'; 
import locationsSaga from './locations/saga'; 
import payrollSaga from './payroll/saga'; 
import policySaga from './policies/saga'; 
import userSaga from './user/saga'; 
import securitySaga from './security/saga'; 
import pollPayrollSaga from './pollPayroll/saga'; 

export default function* () {
    const sagas = [
        adminSaga, 
        claimsSaga,
        companiesSaga,
        contactsSaga,
        dashboardSaga,
        financialsSaga,
        locationsSaga,
        payrollSaga, 
        policySaga, 
        userSaga,
        securitySaga,
        pollPayrollSaga,
    ];

    for (const saga of sagas) {
        try {
            yield put({ type: 'SAGA_RUN' });
            yield fork(saga);
        } catch (e) {
            yield put({ type: 'SAGA_FAILED', e });
        }
    }
}
