import React, { Component } from 'react'
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dashboardIcon from '../../assets/images/SideIcons/icn-dashboard.svg';
import financesIcon from '../../assets/images/SideIcons/icn-finances.svg';
import settingsIcon from '../../assets/images/SideIcons/icn-settings.svg';
import graphIcon from '../../assets/images/chart-arrow-grow.svg';
import claimIcon from '../../assets/images/box.svg';
import payIcon from '../../assets/images/Pay.png'; 
import securityIcon from '../../assets/images/SideIcons/icn-staff.svg';
import queuesIcon from '../../assets/images/SideIcons/icn-queues.svg'
import { version } from '../../../package.json';
import { build } from '../../build.json';
import { userHasPermission } from '../../utils/helpers';
import { USER_PERMISSIONS } from 'store/enums';

// TODO-ALAN: change to a ts or tsx files
export class AppMenu extends Component {
    state = { activeItem: userHasPermission("Access Dashboard") ? 'dashboard' : 'claims' }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    setActive(name) {
        this.setState({ activeItem: name });
    }

    render() {
        const { activeItem } = this.state;
        let year = new Date().getFullYear();

        return (
            <React.Fragment>
                <div className="sidebar-menu-container">
                    <Nav variant="pills" className="flex-column navbar-inverse sidebar-menu">
                        {userHasPermission("Access Dashboard") &&
                            <Nav.Link
                                active={activeItem === 'dashboard'}
                                onClick={() => this.setActive('dashboard')}
                                className="item"
                                as={Link}
                                to="/">
                                <img className="icon" src={dashboardIcon} />
                                Dashboard
                            </Nav.Link>
                        }
                        <Nav.Link
                            active={activeItem === 'claims'}
                            onClick={() => this.setActive('claims')}
                            className="item"
                            as={Link}
                            to="/claims"
                            eventKey="claims"
                        >
                            <img className="icon" src={claimIcon} />
                            Claims
                        </Nav.Link>
                        {process.env.REACT_APP_FEATURE_QUEUES_ENABLED == "true" && 
                            <Nav.Link
                                active={activeItem === 'queues'}
                                onClick={() => this.setActive('queues')}
                                className="item"
                                as={Link}
                                to="/queues"
                                eventKey="queues"
                            >
                                <img className="icon" src={queuesIcon} />
                                Queues
                            </Nav.Link>
                        }
                        {userHasPermission("Access Financials") &&
                            <Nav.Link
                                active={activeItem === 'financials'}
                                onClick={() => this.setActive('financials')}
                                className="item"
                                as={Link}
                                to="/financials">
                                <img className="icon" src={financesIcon} />
                                Financials
                            </Nav.Link>
                        }
                        {userHasPermission(USER_PERMISSIONS.PAYROLL_CAN_ACCESS) &&
                            <Nav.Link
                                active={activeItem === 'payroll'}
                                onClick={() => this.setActive('payroll')}
                                className="item"
                                as={Link}
                                to="/payroll">
                                <img height={30} style={{marginRight:"12px"}} src={payIcon} />
                                Payroll
                            </Nav.Link>
                        }
                        {userHasPermission("Access Reports") &&
                            <Nav.Link
                                active={activeItem === 'reports'}
                                className="item"
                                as={Link}
                                eventKey="reports"
                                target='_blank'
                                to="/reports" >
                                <img className="icon makeWhite" src={graphIcon} />
                                Reports
                            </Nav.Link>
                        }
                        {userHasPermission("Access Settings") &&
                            <Nav.Link
                                active={activeItem === 'admin'}
                                onClick={() => this.setActive('admin')}
                                className="item"
                                as={Link}
                                to="/admin"
                                eventKey="admin">
                                <img className="icon" src={settingsIcon} />
                                Settings
                            </Nav.Link>
                        }
                        {userHasPermission("Security Administrator") &&
                            <Nav.Link
                                active={activeItem === 'security'}
                                onClick={() => this.setActive('security')}
                                className="item"
                                as={Link}
                                to="/security"
                                eventKey="security">
                                <img className="icon" src={securityIcon} />
                                Security
                            </Nav.Link>
                        }
                    </Nav>
                    <div>
                        <p>{version} build {build}</p>
                        <p>Copyright {year}</p>
                        <p>Compass Adjusting Services</p>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}