import { push } from 'connected-react-router';
import store from '../store'; 
import { userHasPermission } from '../utils/helpers';

export const url = {
    admin: '/admin',    
    claimProfile: '/claim/:claimId',
    claimList: '/claims',
    queues: '/queues',
    companyDetail: '/admin/company/:companyId',
    contactDetail: '/admin/contact/:contactId',
    dashboard: '/',
    financials: '/financials', 
    notFound: '/404', 
    payroll: '/payroll', 
    reports: '/reports', 
    loginCallback: '/login_callback',
    logout: '/logout',
    printf: '/printf',
    security: '/security',
    unauthorized: '/unauthorized',    
};

export const goto = (path: string) => {
    store.dispatch(push(path));
};

export const gotoWithState = (path: string, state: object) => {
    store.dispatch(push(path, state));
};