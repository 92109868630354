import * as React from 'react'; 
import {Card} from 'react-bootstrap'; 
import NumberFormat from 'react-number-format';
import * as helpers from '../../utils/helpers'; 

interface Props {
    title: string; 
    value: number; 
    valueType?: string; 
}

interface State {
}

class MetricCard extends React.PureComponent<Props, State> {
    public state = {   
    }
 
    public render() {    
      const { valueType } = this.props; 
      const prefix = valueType === 'money' ? '$' : ''; 

      return (
        <Card className="metric-card">
            <Card.Title>{this.props.title}</Card.Title>
            <Card.Body>
                <div className='dash-card-value'>{helpers.nFormatter(this.props.value, 1, prefix)}</div>
                <br></br>
            </Card.Body>
        </Card>
      );
    }
}

export default MetricCard; 

