import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models';
import * as actions from './actions';
import * as constants from './constants';

export interface PollPayrollStateShape {
    payrollStatus: models.PayrollStatusResponse;     
}

export const pollPayrollDefaultState: PollPayrollStateShape = {
    payrollStatus: {
        payrollId: 0, 
        status: ""
    }, 
}

export interface State { 
    pollPayroll: PollPayrollStateShape; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    pollPayroll: (state = pollPayrollDefaultState, action) => {
        switch (action.type) {
            case constants.RECEIVE_PAYROLL_STATUS: 
                return {
                    ...state,
                    payrollStatus: action.payload.response, 
                }

            default: 
                return state;                 
        }
    }
});
