import * as React from 'react';
import { Table } from 'react-bootstrap'; 
import * as models from '../../models';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';

interface Props {    
    fees: models.PayrollFee[];
}

interface State {    
}

class PayrollFeeTable extends React.Component<Props, State> {
    public state = {        
    };    

    public render() {
        const { fees } = this.props;        

        return (
            <>
            {fees[0] &&
                <Table striped>
                    <thead>
                        <tr>                                                    
                            <th>Claim #</th>
                            <th>Invoice #</th>
                            <th>Invoice Dt</th>
                            <th>Fee Name</th>
                            <th>Fee Amt</th>   
                            <th>Adjuster %</th>
                            <th>Paid</th>                            
                            <th>Payment Date</th>
                            <th>Payroll Amt</th>
                            <th>Advance</th>
                        </tr>
                    </thead>
                    <tbody>
                    {fees.map((x: models.PayrollFee, i: number) => (
                        <tr key={i}>                            
                            <td>{x.claimNumber}</td>
                            <td>{x.invoiceNumber}</td>
                            <td>{dateFormat(x.invoiceDate, 'mm/dd/yyyy')}</td>
                            <td>{x.feeName}</td>
                            <td><NumberFormat value={x.feeAmt.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                            <td><NumberFormat value={x.adjusterPercent} displayType={'text'} suffix={'%'}/></td>
                            <td><NumberFormat value={x.paid.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                            <td>{dateFormat(x.paymentDate, 'mm/dd/yyyy')}</td>
                            <td><NumberFormat value={x.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            <td>{x.isAdvance ? "Yes" : "No"}</td>                            
                        </tr>
                    ))}
                    </tbody>
                </Table>
            }
            </>
        );
    }
}
export default PayrollFeeTable;
