import * as React from 'react';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Card, CardGroup, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import _ from 'lodash'; 
import * as models from '../../models'
import { payrollActions } from 'store/payroll';
import infoIcon from '../../assets/images/Blue/info-circle.svg'; 

interface Props extends PropsFromRedux {
}

interface State {
    adjusterFeeRequset: models.AdjusterFeeListRequest,
}
class AdjusterFeeSearch extends React.Component<Props, State> {
    public state = {  
        adjusterFeeRequset: {...this.props.adjusterFeesRequest}, 
    }   

    public componentDidMount = () => {  
        if(this.props.adjusterFeeListResponse.totalCount === 0) {
            this.fetchFormData(this.state.adjusterFeeRequset);
        }
    }
    
    private handleSearchSubmit = async (e: any) => {
        const { adjusterFeeRequset } = this.state; 
        this.fetchFormData(adjusterFeeRequset); 
        e.preventDefault(); 
    };

    public fetchFormData = (request: models.AdjusterFeeListRequest) => {   
        this.props.requestAdjusterFeesList(request);        
    }

    public resetFilters = () => {
        this.setState({
            adjusterFeeRequset: {...models.defaultAdjusterFeeListRequest}, 
        }); 
        this.fetchFormData(models.defaultAdjusterFeeListRequest);
    }

    public render() {     
        const { adjusterFeeRequset } = this.state; 
        const { adjusterFeeListResponse } = this.props; 

        return (
            <>
                <Card className="claim-card">
                    <Card.Title>Adjuster Fees Search
                    </Card.Title>
                    <Form onSubmit={this.handleSearchSubmit}>
                        <Card.Body style={{display: "flex", flexDirection: "column"}}>
                            <Row xs={2} md={2}>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Claim Number</Form.Label>
                                                <Form.Control 
                                                    name="insClaimNo" 
                                                    placeholder='Claim #' 
                                                    value={adjusterFeeRequset.insClaimNo || ""} 
                                                    onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, insClaimNo: e.target.value}})} 
                                                    />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Insurance Company</Form.Label>
                                                <Form.Select 
                                                    name="insCompany" 
                                                    value={adjusterFeeRequset.insCompany} 
                                                    onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, insCompany: e.target.value}})}  
                                                >
                                                    <option key={-1} value=""></option>
                                                    {adjusterFeeListResponse.insuranceCompanies && adjusterFeeListResponse.insuranceCompanies.map((c: models.DropDownListItem, i: number) => (
                                                        <option key={i} value={c.name} >{c.name}</option>
                                                    ))}
                                                </Form.Select>                                    
                                            </Form.Group>    
                                        </Col>
                                        <Col>
                                        <Form.Group>
                                                <Form.Label>Invoice Number</Form.Label>
                                                <Form.Control 
                                                    name="invNumber" 
                                                    placeholder='Invoice #...' 
                                                    value={adjusterFeeRequset.invoiceNumber} 
                                                    onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, invoiceNumber: e.target.value}})}  
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingTop: ".5em"}}>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Field Adjuster</Form.Label>
                                                <Form.Select 
                                                    name="fieldAdjuster" 
                                                    value={adjusterFeeRequset.fieldAdjuster} 
                                                    onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, fieldAdjuster: e.target.value}})}  
                                                >
                                                    <option key={-1} value=""></option>
                                                    {adjusterFeeListResponse.payrollAdjusters && adjusterFeeListResponse.payrollAdjusters.map((c: models.DropDownListItem, i: number) =>
                                                        <option key={i} value={c.name}>{c.name}</option>
                                                    )}
                                                </Form.Select>
                                            </Form.Group>  
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Insured Name</Form.Label>
                                                <Form.Control 
                                                    name="insuredName" 
                                                    placeholder='Insured Name...' 
                                                    value={adjusterFeeRequset.insuredName} 
                                                    onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, insuredName: e.target.value}})}  
                                                />
                                            </Form.Group>  
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Cat Code</Form.Label>
                                                <Form.Select 
                                                    name="catCode" 
                                                    value={adjusterFeeRequset.catCode} 
                                                    onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, catCode: e.target.value}})}  
                                                >
                                                    <option key={-1} value=""></option>
                                                    {adjusterFeeListResponse.catCodes && adjusterFeeListResponse.catCodes.map((c: models.DropDownListItem, i: number) => (
                                                        <option key={i} value={c.name} >{c.name}</option>
                                                    ))}
                                                </Form.Select>                                    
                                            </Form.Group> 
                                        </Col>                                        
                                    </Row>                                    
                                </Col>
                                <Col>
                                    <CardGroup>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Invoice Date</Card.Title>
                                                <Form.Group>
                                                    <Row>   
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="invDateFrom" 
                                                                value={adjusterFeeRequset.invoiceDateFrom} 
                                                                onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, invoiceDateFrom: e.target.value}})} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="invDateTo" 
                                                                value={adjusterFeeRequset.invoiceDateTo} 
                                                                onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, invoiceDateTo: e.target.value}})} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Payment Rcvd Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="paymentDateFrom" 
                                                                value={adjusterFeeRequset.paymentDateFrom} 
                                                                onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, paymentDateFrom: e.target.value}})} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="paymentDateTo" 
                                                                value={adjusterFeeRequset.paymentDateTo} 
                                                                onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, paymentDateTo: e.target.value}})} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </CardGroup>
                                </Col> 
                            </Row>
                            <Row style={
                                {
                                    marginTop: "1em",
                                    marginLeft: "0", 
                                    marginRight: ".5em", 
                                    borderStyle: "solid", 
                                    borderRadius: ".5em", 
                                    borderWidth: ".1em", 
                                    display: "flex",
                                    justifyContent: "space-around"
                                }
                                }><div style={{padding: ".5em", fontStyle: "italic"}}>Limiters
                                        <OverlayTrigger                                                                                                                 
                                            key="overlayTrigger"
                                            placement="top"                                                            
                                            overlay={
                                                <Tooltip className="my-tooltip" id="toolTip">Setting a limiter to 'True' will only return those that match.  Leaving them as 'False' returns all results.</Tooltip>
                                            }
                                        >
                                            <img className="info-icon" src={infoIcon}/>
                                        </OverlayTrigger>
                                </div>
                                <Row style={{paddingBottom: ".5em"}}>                                
                                    <Form.Group as={Col}>
                                        <Form.Check 
                                            id="chkIsPayrolled"
                                            inline={true}                                        
                                            name="isPayrolled"                                        
                                            label="Is Payrolled"
                                            type="checkbox"
                                            checked={adjusterFeeRequset.isPayrolled}                                        
                                            onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, isPayrolled: !adjusterFeeRequset.isPayrolled }})}  
                                        />          
                                    </Form.Group>                                              
                                    <Form.Group as={Col}>
                                        <Form.Check 
                                            inline={true}
                                            name="hasAdjBal"
                                            type="checkbox"
                                            label="Has Adjuster Balance"
                                            id="chkHasAdjBal"
                                            checked={adjusterFeeRequset.hasAdjusterBalance}
                                            onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, hasAdjusterBalance: !adjusterFeeRequset.hasAdjusterBalance }})}  
                                        />          
                                    </Form.Group> 
                                    <Form.Group as={Col}>
                                        <Form.Check 
                                            inline={true}
                                            name="hasAdj%"
                                            type="checkbox"
                                            label="Has Adjuster Percent"
                                            id="chkHasAdj%"
                                            checked={adjusterFeeRequset.hasAdjusterPercent}
                                            onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, hasAdjusterPercent: !adjusterFeeRequset.hasAdjusterPercent }})}  
                                        />          
                                    </Form.Group> 
                                    <Form.Group as={Col}>
                                        <Form.Check 
                                            inline={true}
                                            name="isAdvance"
                                            type="checkbox"
                                            label="Is Advance"
                                            id="chkIsAdvance"
                                            checked={adjusterFeeRequset.isAdvance}
                                            onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, isAdvance: !adjusterFeeRequset.isAdvance }})}  
                                        />          
                                    </Form.Group>  
                                    <Form.Group as={Col}>
                                        <Form.Check 
                                            inline={true}
                                            name="isCorrection"
                                            type="checkbox"
                                            label="Is Correction"
                                            id="chkIsCorrection"
                                            checked={adjusterFeeRequset.isCorrection}
                                            onChange={(e) => this.setState({adjusterFeeRequset: {...adjusterFeeRequset, isCorrection: !adjusterFeeRequset.isCorrection }})}  
                                        />          
                                    </Form.Group>                                 
                                </Row>
                            </Row> 
                            <Row>
                                <Col>
                                    <Button className="claim-search-btn" type='submit'>Search</Button>
                                    <Button className="claim-search-btn" onClick={this.resetFilters}>Reset Filters</Button>
                                </Col>
                            </Row>                           
                        </Card.Body>
                    </Form>
                </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    adjusterFeesRequest: state.payroll.search.adjusterFeeListRequest, 
    adjusterFeeListResponse: state.payroll.search.adjusterFeeListResponse, 
}); 

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {       
            requestAdjusterFeesList: payrollActions.requestAdjusterFeesList, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(AdjusterFeeSearch);