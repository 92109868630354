import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PageHeader from 'components/common/PageHeader';
import '../../assets/styles/Financials.css'; 
import AdjusterFeeSearch from './AdjusterFeeSearch';
import ReportingTable from './ReportingTable';
import PayrollSearch from './PayrollSearch';
import PayrollList from './PayrollList';
import PayrollDashboard from '../dashboards/payroll'; 
import { payrollActions } from 'store/payroll';
import * as models from '../../models'; 

interface Props extends PropsFromRedux {
}

interface State {
    activeTab: string,
}

class Payroll extends React.Component<Props, State> {
    public state = {
        activeTab: "Dashboard",
    }

    public componentDidMount = () => {        
        this.props.requestPayrollList(models.defaultPayrollListRequest); 
        this.props.requestAdjusterFeesList(models.defaultAdjusterFeeListRequest); 
    };

    public handleTabs = (name: string) => {
        this.setState({ activeTab: name });
    };

    public renderTab = () => {
        const { activeTab } = this.state;   

        switch (activeTab) {
            case "Dashboard":
                return (
                    <PayrollDashboard/>
                ); 

            case "Reporting":
                return (
                    <>
                        <AdjusterFeeSearch/>
                        <ReportingTable/>
                    </>
                );

            case "Payroll":
                return (
                    <>
                        <PayrollSearch/>
                        <PayrollList/>
                    </>
                );          

            default:            
                return <div>No Info</div>;
        }
    }

    public render() {
        return (
            <div>
                <PageHeader 
                    getTabName={this.handleTabs} 
                    tabs={["Dashboard", "Payroll", "Reporting"]}
                />
                {this.renderTab()}                
            </div>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {                        
            requestPayrollList: payrollActions.requestPayrollList, 
            requestAdjusterFeesList: payrollActions.requestAdjusterFeesList, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Payroll);
