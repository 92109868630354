import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models';
import * as actions from './actions';
import * as constants from './constants';

export interface PayrollStateShape {
    adjusterFeeResponse: models.AdjusterFeeResponse;    
    postPayrollResponse: number; 
    payrollDetail: models.PayrollDetail; 
    isAdjusterFeesLoading: boolean; 
    isPayrollLoading: boolean; 
    isPayrollRunning: boolean; 
    isZipDownloading: boolean;     
}

export const payrollDefaultState: PayrollStateShape = {
    adjusterFeeResponse: {
        ...models.defaultResponse,   
        catCodes: new Array<models.DropDownListItem>(),  
        payrollAdjusters: new Array<models.DropDownListItem>(), 
        insuranceCompanies: new Array<models.DropDownListItem>()
    }, 
    postPayrollResponse: -1, 
    payrollDetail: models.defaultPayrollDetail, 
    isAdjusterFeesLoading: false, 
    isPayrollLoading: false, 
    isPayrollRunning: false, 
    isZipDownloading: false, 
}

export interface PayrollSearchShape {    
    adjusterFeeListRequest: models.AdjusterFeeListRequest, 
    adjusterFeeListResponse: models.AdjusterFeeResponse, 
    isAdjusterFeeListLoading: boolean; 
    isAdjusterFeeListDownloading: boolean; 
    isPayrollCSVDownloading: boolean; 
    isPayrollListLoading: boolean;     
    payrollListResponse: models.PayrollListResponse; 
    payrollListRequest: models.PayrollListRequest; 
}

export const PayrollSearchDefaultState: PayrollSearchShape = {  
    adjusterFeeListRequest: models.defaultAdjusterFeeListRequest, 
    adjusterFeeListResponse: {
        ...models.defaultResponse, 
        catCodes: new Array<models.DropDownListItem>(),
        insuranceCompanies: new Array<models.DropDownListItem>(),
        payrollAdjusters: new Array<models.DropDownListItem>(),
    }, 
    payrollListResponse: {
        ...models.defaultResponse,
        insuranceCompanies: new Array<models.DropDownListItem>(),
        fieldAdjusters: new Array<models.DropDownListItem>(),
    },
    payrollListRequest: models.defaultPayrollListRequest,
    isAdjusterFeeListLoading: false, 
    isAdjusterFeeListDownloading: false, 
    isPayrollCSVDownloading: false,
    isPayrollListLoading: false, 
}

export interface State { 
    payroll: PayrollStateShape; 
    search: PayrollSearchShape; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    payroll: (state = payrollDefaultState, action) => {
        switch (action.type) {
            case constants.ADD_PAYROLL:
                return {
                    ...state,
                    isPayrollRunning: true, 
                }; 
            case constants.ADD_PAYROLL_COMPLETE:
                return {
                    ...state, 
                    isPayrollRunning: false, 
                    postPayrollResponse: action.payload.response,
                }; 

            case constants.CLEAR_ADJUSTER_FEES_LIST: 
                return {
                    ...state, 
                    adjusterFeeResponse: {
                        ...models.defaultResponse,
                        catCodes: new Array<models.DropDownListItem>(),
                        payrollAdjusters: new Array<models.DropDownListItem>(),
                        insuranceCompanies: new Array<models.DropDownListItem>(),
                    }
                }; 

            case constants.REQUEST_PAYROLL: 
                return {
                    ...state,
                    isPayrollLoading: true, 
                }; 
            case constants.RECEIVE_PAYROLL: 
                return {
                    ...state, 
                    isPayrollLoading: false, 
                    payrollDetail: action.payload.response, 
                }                

            case constants.REQUEST_ADJUSTER_FEES: 
                return {
                    ...state, 
                    adjusterFeeResponse: {
                        ...models.defaultResponse, 
                        catCodes: new Array<models.DropDownListItem>(),
                        payrollAdjusters: new Array<models.DropDownListItem>(),
                        insuranceCompanies: new Array<models.DropDownListItem>(),
                    }, 
                    isAdjusterFeesLoading: true, 
                }; 

            case constants.RECEIVE_ADJUSTER_FEES: 
                return {
                    ...state, 
                    adjusterFeeResponse: action.payload.response,
                    isAdjusterFeesLoading: false, 
                }            

            case constants.REQUSET_PAYROLL_ZIPFILE: 
                return {
                    ...state, 
                    isZipDownloading: true, 
                }

            case constants.RECEIVE_PAYROLL_ZIPFILE: 
                return {
                    ...state,
                    isZipDownloading: false, 
                }

            default: 
                return state; 
        }
    }, 
    search: (state = PayrollSearchDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_ADJUSTER_FEES_LIST: 
                return {
                    ...state, 
                    isAdjusterFeeListLoading: true, 
                    adjusterFeeListRequest: action.payload.request,
                }
            case constants.RECEIVE_ADJUSTER_FEES_LIST: 
                return {
                    ...state, 
                    isAdjusterFeeListLoading: false, 
                    adjusterFeeListResponse: action.payload.response, 
                }

            case constants.REQUEST_ADJUSTER_FEES_LIST_CSV: 
                return {
                    ...state,
                    isAdjusterFeeListDownloading: true,
                }
            case constants.RECEIVE_ADJUSTER_FEES_LIST_CSV: 
                return {
                    ...state, 
                    isAdjusterFeeListDownloading: false, 
                }
            
            case constants.REQUEST_PAYROLL_LIST: 
                return {
                    ...state, 
                    isPayrollListLoading: true,
                    payrollListRequest: action.payload.request,
                }
            case constants.RECEIVE_PAYROLL_LIST: 
                return {
                    ...state,
                    isPayrollListLoading: false, 
                    payrollListResponse: action.payload.response, 
                }
            
            case constants.REQUEST_PAYROLL_CSV: 
                return {
                    ...state, 
                    isPayrollCSVDownloading: true, 
                }
            case constants.RECEIVE_PAYROLL_CSV: 
                return {
                    ...state, 
                    isPayrollCSVDownloading: false, 
                }

            default: 
                return state; 
        }
    }, 
});
