export interface PayrollByAdjuster {
    adjuster: string; 
    payrollAmt: number; 
}

export interface PayrollByMonth {
    order: number; 
    month: string; 
    amount: number; 
    advancedAmount: number; 
}

export interface PayrollDashboard {
    ytdActiveAdjusters: number; 
    ytdPayrollAmt: number; 
    ytdAdvancedAmt: number; 
    fundsAwaitingPayout: number; 
    topTenAdjusters: PayrollByAdjuster[]; 
    payrollByMonth: PayrollByMonth[]; 
}

export const defaultPayrollDashboard: PayrollDashboard = {
    ytdActiveAdjusters: 0, 
    ytdPayrollAmt: 0, 
    ytdAdvancedAmt: 0, 
    fundsAwaitingPayout: 0, 
    topTenAdjusters: [] as PayrollByAdjuster[],
    payrollByMonth: [] as PayrollByMonth[], 
}