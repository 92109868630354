import { request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 
import { add } from 'rfc6902/patch';

export const addFee = async (fee: models.Fee) => {
  const response = await request ({
    method: 'POST', 
    url: `/Fees`, 
    data: fee,
  }); 
  return response; 
};

export const addFeeToSchedule = async (id: number, fee: models.Fee) => {
  const response = await request ({
    method: 'POST', 
    url: `/FeeSchdules/${id}/fees`, 
    data: fee,
  }); 
  return response; 
};

export const addFeeScheduleToInvoice = async (id: number, body: models.AddFeeScheduleToInvoice) => {
  return await request({
    method: 'POST',
    url: `/feeSchedules/${id}/invoice`,
    data: body, 
  }); 
}; 

export const addInvoiceCorrection = async (invoiceId: number, correction: models.AddInvoiceCorrection) => {
  return await request({
    method: 'POST', 
    url: `/invoices/${invoiceId}/corrections`,
    data: correction,
  }); 
}; 

export const addInvoiceGroup = async (invoiceGroup: models.InvoiceGroup) => {
  const response = await request ({
    method: 'POST',
    url: `/invoiceGroups`, 
    data: invoiceGroup,
  }); 
  return response; 
}

export const addPayment = async (addPayment: models.AddPayment) => {
  const response = await request ({
    method: 'POST',
    url: `/payments`,
    data: addPayment,
  }); 
  return response; 
}

export const createFeeSchedule = async (feeSchedule: models.FeeSchedule) => {
  const response = await request({
    method: 'POST',
    url: `/feeSchedules`,
    data: feeSchedule,
  }); 
  return response; 
}

export const buildPDF = async (id: number) => {
  const response = await request ({
    method: 'POST', 
    url: `/invoices/${id}/pdf`,
  });
  return response; 
}

export const deleteFee = async (id: number) => {  
  const response = await request ({
    method: 'DELETE', 
    url: `/Fees/${id}`, 
  }); 
  return response; 
}; 

export const deleteFeeSchedule = async (id: number) => {  
  const response = await request ({
    method: 'DELETE', 
    url: `/FeeSchedules/${id}`, 
  }); 
  return response; 
}; 

export const getAdjusterFeeList = async (search: models.AdjusterFeeRequest) => {
  var qStr = utils.helpers.encodeQueryString(search);  
  
  const response = await request({
    method: 'GET',
    url: `/invoices/adjusterFees?${qStr}`, 
  });
  return response; 
}; 

export const getConnectAdjusterFees = async (adjusterId: number) => {
  const response = await request ({
    method: 'GET', 
    url: `/Contacts/${adjusterId}/fees`, 
  }); 
  return response; 
};

export const getFees = async () => {  
  const response = await request ({
    method: 'GET', 
    url: `/Fees`, 
  }); 
  return response; 
}; 

export const getFeeSchedulesByCompany = async (company: string) => {  
  const response = await request ({
    method: 'GET', 
    url: `/FeeSchedules/${company}`, 
  }); 
  return response; 
}; 

export const getFeeSchedule = async (feeScheduleId: number) => {
  const response = await request ({
    method: 'GET', 
    url: `/FeeSchedules/${feeScheduleId}`, 
  }); 
  return response; 
};

export const getInvoice = async (invoiceId: number) => {    
  const response = await request ({
    method: 'GET', 
    url: `/invoices/${invoiceId}`, 
  }); 
  return response; 
};

export const getInvoiceEventsByClaim = async (claimId: number) => {
  const response = await request ({
    method: 'GET', 
    url: `/claims/${claimId}/invoiceEvents`, 
  }); 
  return response; 
}

export const getInvoiceAdjusterDD = async (invoicesRequest: models.InvoicesRequest) => {
  var qStr = utils.helpers.encodeQueryString(invoicesRequest);  
  const response = await request ({
    method: 'GET', 
    url: `/invoices/adjusters?${qStr}`, 
  }); 
  return response; 
}; 

export const getInvoiceAttachment = async (invoiceId: number) => {
  return await request({
      method: 'GET',
      url: `/invoices/${invoiceId}/pdf`,
      responseType: 'blob',
  }); 
}; 

export const getInvoiceAttachment2 = async (invoiceId: number, attachmentId: number) => {
  return await request({
      method: 'GET',
      url: `/invoices/${invoiceId}/pdf/${attachmentId}`,
      responseType: 'blob',
  }); 
}; 

export const getInvoiceCompanyDD = async (invoicesRequest: models.InvoicesRequest) => {
  var qStr = utils.helpers.encodeQueryString(invoicesRequest);  
  const response = await request ({
    method: 'GET', 
    url: `/invoices/companies?${qStr}`, 
  }); 
  return response; 
}; 

export const getInvoiceGroupDD = async (invoicesRequest: models.InvoicesRequest) => {
  var qStr = utils.helpers.encodeQueryString(invoicesRequest);  
  const response = await request ({
    method: 'GET', 
    url: `/invoices/groups?${qStr}`, 
  }); 
  return response; 
}; 

export const getInvoiceGroup = async (invoiceGroupId: number) => {  
  const response = await request ({
    mehod: 'GET',
    url: `/invoiceGroups/${invoiceGroupId}`,
  }); 
  return response; 
}; 

export const getInvoiceGroups = async (invoiceGroupRequest: models.InvoiceGroupsRequest) => {
  var qStr = utils.helpers.encodeQueryString(invoiceGroupRequest);  
  const response = await request ({
    method: 'GET', 
    url: `/invoiceGroups?${qStr}`, 
  }); 
  return response; 
}; 

export const getInvoicesCSV = async (invoicesRequest: models.InvoicesRequest) => {
    var qStr = "";
    if (invoicesRequest) {
        qStr = "?q=" + encodeURI(JSON.stringify(invoicesRequest));
    }    
    const response = await request ({
        method: 'GET',
        url: `/invoices/export${qStr}`, 
    }); 
    
    if (response.isSuccess) {
        const responseData = await utils.filehelpers.readAsDataURL(new Blob([response.data], {type: "application/csv"}));
        try {
            utils.filehelpers.downloadFile(response);

        } catch (err) {
            return {
                error: err,
              };
        }     
    return true; 
    }
}

export const getInvoicesList = async (invoicesRequest: models.InvoicesRequest) => {
  var qStr = utils.helpers.encodeQueryString(invoicesRequest);    
  const response = await request({
      method: 'GET',
      url: `/invoices?${qStr}`, 
  });  
  return response; 
}; 

export const getInvoicesByBulkName = async (name: string) => {
  const response = await request({
    method: 'GET',
    url: `/invoiceGroups/${name}/invoices`,
}); 
return response; 
}; 

export const patchFee =async (id: any, patch: object) => {    
  return await request({
      method: 'PATCH',
      url: `/fees/${id}`,
      data: patch,
  }); 
}; 

export const patchInvoice = async (id: number, patch: object) => {
  return await request({
    method: 'PATCH',
    url: `/invoices/${id}`,
    data: patch,
  }); 
}; 

export const patchFeeSchedule = async (id: number, patch: object) => {
  return await request({
    method: 'PATCH',
    url: `/feeSchedules/${id}`,
    data: patch,
  }); 
}; 

export const replaceInvoice = async (id: number, invoice: models.Invoice) => {
  return await request({
    method: 'PUT', 
    url: `/invoices/${id}`,
    data: invoice
  }); 
}; 