import * as React from 'react';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps, shallowEqual } from 'react-redux';
import { Card, Form, Button, Row, Col, CardGroup } from 'react-bootstrap';
import SingleActionButton from '../common/SingleActionButton';
import addIcon from '../../assets/images/Blue/add.svg';
import _ from 'lodash'; 
import * as models from '../../models/'
import { payrollActions } from 'store/payroll';
import PayrollDetail from './PayrollDetail';

interface Props extends PropsFromRedux {
}

interface State {
    payrollListRequest: models.PayrollListRequest, 
    showDetailsModal: boolean, 
}
class PayrollSearch extends React.Component<Props, State> {
    public state = {  
        payrollListRequest: {...this.props.payrollListRequest},    
        showDetailsModal: false, 
    }

    componentDidUpdate(prevProps: any, prevState: any) {
    }   

    public componentDidMount = () => {  
        if(this.props.payrollListResponse.totalCount === 0) {
            this.fetchFormData(this.state.payrollListRequest);
        }
    }
    
    private handleSearchSubmit = async (e: any) => {
        const { payrollListRequest } = this.state; 
        this.fetchFormData(payrollListRequest); 
        e.preventDefault(); 
    };

    public clearForm = () => {             
    }

    public togglePayrollDetails = () => {
        this.setState({showDetailsModal: !this.state.showDetailsModal}); 
    }

    public fetchFormData = (request: models.PayrollListRequest) => {   
        this.props.requestPayrollList(request);        
    }

    public resetFilters = () => {
        this.setState({
            payrollListRequest: {...models.defaultPayrollListRequest}, 
        }); 
        this.fetchFormData(models.defaultPayrollListRequest);
    }

    public render() {     
        const { payrollListRequest, showDetailsModal } = this.state; 
        const { payrollListResponse } = this.props; 

        return (
            <>
                <PayrollDetail
                    showModal={showDetailsModal}
                    closeModal={this.togglePayrollDetails}
                />
                <Card className="claim-card">
                    <Card.Title>Payroll Search
                        <SingleActionButton
                            action="Add"
                            title="Payroll"
                            img={addIcon}
                            onClick={this.togglePayrollDetails}
                        />
                    </Card.Title>
                    <Form onSubmit={this.handleSearchSubmit}>
                        <Card.Body style={{display: "flex", flexDirection: "column"}}>
                            <Row xs={2} md={2}>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Claim Number</Form.Label>
                                                <Form.Control 
                                                    name="insClaimNo" 
                                                    placeholder='Claim #' 
                                                    value={payrollListRequest.insClaimNo || ""} 
                                                    onChange={(e) => this.setState({payrollListRequest: {...payrollListRequest, insClaimNo: e.target.value}})} 
                                                    />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Insurance Company</Form.Label>
                                                <Form.Select 
                                                    name="insCompany" 
                                                    value={payrollListRequest.insCompany} 
                                                    onChange={(e) => this.setState({payrollListRequest: {...payrollListRequest, insCompany: e.target.value}})}  
                                                >
                                                    <option key={-1} value=""></option>
                                                    {payrollListResponse.insuranceCompanies && payrollListResponse.insuranceCompanies.map((c: models.DropDownListItem, i: number) => (                                             
                                                        <option key={i} value={c.name} >{c.name}</option>
                                                    ))}
                                                </Form.Select>                                    
                                            </Form.Group>    
                                        </Col>
                                    </Row>
                                    <Row style={{paddingTop: ".5em"}}>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Field Adjuster</Form.Label>
                                                <Form.Select 
                                                    name="fieldAdjuster" 
                                                    value={payrollListRequest.fieldAdjuster} 
                                                    onChange={(e) => this.setState({payrollListRequest: {...payrollListRequest, fieldAdjuster: e.target.value}})}  
                                                >
                                                    <option key={-1} value=""></option>
                                                    {payrollListResponse.fieldAdjusters && payrollListResponse.fieldAdjusters.map((c: models.DropDownListItem, i: number) =>
                                                        <option key={i} value={c.name}>{c.name}</option>
                                                    )}
                                                </Form.Select>
                                            </Form.Group>  
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Insured Name</Form.Label>
                                                <Form.Control 
                                                    name="insuredName" 
                                                    placeholder='Insured Name...' 
                                                    value={payrollListRequest.insuredName} 
                                                    onChange={(e) => this.setState({payrollListRequest: {...payrollListRequest, insuredName: e.target.value}})}  
                                                />
                                            </Form.Group>  
                                        </Col>
                                    </Row>
                                    <Row style={{paddingTop: ".5em"}}>
                                        <Col sm={{span: 6}}>
                                            <Form.Group>
                                                <Form.Label>Payment Info (Check #)</Form.Label>
                                                <Form.Control 
                                                    name="paymentInfo" 
                                                    placeholder='Payment Info...' 
                                                    value={payrollListRequest.paymentInfo} 
                                                    onChange={(e) => this.setState({payrollListRequest: {...payrollListRequest, paymentInfo: e.target.value}})}  
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                <CardGroup>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Run Date</Card.Title>
                                                <Form.Group>
                                                    <Row>   
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="runDateFrom" 
                                                                value={payrollListRequest.runDateFrom} 
                                                                onChange={(e) => this.setState({payrollListRequest: {...payrollListRequest, runDateFrom: e.target.value}})} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="runDateTo" 
                                                                value={payrollListRequest.runDateTo} 
                                                                onChange={(e) => this.setState({payrollListRequest: {...payrollListRequest, runDateTo: e.target.value}})} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Payment Rcvd Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="paymentDateFrom" 
                                                                value={payrollListRequest.paymentDateFrom} 
                                                                onChange={(e) => this.setState({payrollListRequest: {...payrollListRequest, paymentDateFrom: e.target.value}})} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="paymentDateTo" 
                                                                value={payrollListRequest.paymentDateTo} 
                                                                onChange={(e) => this.setState({payrollListRequest: {...payrollListRequest, paymentDateTo: e.target.value}})} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </CardGroup>
                                </Col> 
                            </Row>
                            <Row>
                                <Col>
                                    <Button className="claim-search-btn" type='submit'>Search</Button>
                                    <Button className="claim-search-btn" onClick={this.resetFilters}>Reset Filters</Button>
                                </Col>
                            </Row>                           
                        </Card.Body>
                    </Form>
                </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    payrollListRequest: state.payroll.search.payrollListRequest,
    payrollListResponse: state.payroll.search.payrollListResponse, 
}); 

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {       
            requestPayrollList: payrollActions.requestPayrollList, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(PayrollSearch);
