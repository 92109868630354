import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PageHeader from 'components/common/PageHeader';
import FeeSchedules from './FeeSchedules'; 
import Fees from './Fees'; 
import Invoicing from './Invoicing'; 
import '../../assets/styles/Financials.css'; 
import { financialsActions } from 'store/financials';

interface Props extends PropsFromRedux {
}

interface State {
    activeTab: string,
}

class Financials extends React.Component<Props, State> {
    public state = {
        activeTab: "Invoice Search & Reconcile",
    }

    public componentDidMount = () => {
        this.props.requestFeesList();
      
    };

    public handleTabs = (name: string) => {
        this.setState({ activeTab: name });
    };

    public renderTab = () => {
        const { activeTab } = this.state;        
        const { feesResponse } = this.props; 

        switch (activeTab) {
            case "Fees":
                return (
                    <div>
                        <Fees feesResponse={feesResponse} />
                    </div>
                ); 

            case "Schedules":
                return (
                    <div>
                        <FeeSchedules />
                    </div>
                );

            case "Invoice Search & Reconcile":
                return (
                    <div>
                        <Invoicing />
                    </div>
                );          

            default:            
                return <div>No Info</div>;
        }
    }

    public render() {
        return (
            <div>
                <PageHeader 
                    getTabName={this.handleTabs} 
                    tabs={["Invoice Search & Reconcile", "Schedules",  "Fees"]}
                />
                {this.renderTab()}
            </div>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    feesResponse: state.financials.financials.feesResponse,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            requestFeesList: financialsActions.requestFeesList, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Financials);
