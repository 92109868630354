import { all, call, put, takeEvery, select, take, race, delay } from 'redux-saga/effects';
import { action, ActionType } from 'typesafe-actions';
import services from '../../services';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';
import { USER_ACTIONS } from '../../store/enums'; 
import { userActions } from 'store/user';
import { AppToastTypes } from 'models/enums';
import * as helpers from '../../utils/helpers'; 

export default function* () {
    yield takeEvery(constants.ADD_PAYROLL, addPayroll);     
    yield takeEvery(constants.DELETE_PAYROLL, deletePayroll);     
    yield takeEvery(constants.REQUEST_ADJUSTER_FEES, requestAdjusterFees); 
    yield takeEvery(constants.REQUEST_ADJUSTER_FEES_LIST, requestAdjusterFeesList); 
    yield takeEvery(constants.REQUEST_ADJUSTER_FEES_LIST_CSV, requestAdjusterFeesListCSV); 
    yield takeEvery(constants.REQUEST_PAYROLL, requestPayroll); 
    yield takeEvery(constants.REQUEST_PAYROLL_CSV, requestPayrollCSV); 
    yield takeEvery(constants.REQUEST_PAYROLL_LIST, requestPayrollList); 
    yield takeEvery(constants.REQUSET_PAYROLL_ZIPFILE, requestPayrollZipFile);         
}

export function* addPayroll(action: ActionType<typeof actions.addPayroll>): any {
    yield put(userActions.setLastUserAction(USER_ACTIONS.PAYROLL_CREATING)); 
    const userRequest = yield call(services.api.payroll.addPayroll, action.payload.request);     
    if (userRequest.isSuccess){
        yield put(userActions.setAppToast({message: 'Payroll Created', type: AppToastTypes.Success}));        
        yield put(userActions.setLastUserAction(USER_ACTIONS.PAYROLL_CREATING_SUCCESS)); 
        yield put(actions.addPayrollComplete(userRequest.data));        
        const state = yield select();
        yield put(actions.requestPayrollList(state.payroll.search.payrollListRequest)); 
        const request: models.AdjusterFeeRequest = {
                    isPaid: false, 
                    hasAdjusterAmt: true, 
                }  
        yield put(actions.requestAdjusterFees(request));         
    }
    else {
        console.log(userRequest); 
        yield put(userActions.setLastUserAction(USER_ACTIONS.PAYROLL_CREATING_FAILURE)); 
        const errorMessage = helpers.getResponseErrors(userRequest.data); 
        yield put(userActions.setAppToast({message: errorMessage, type: AppToastTypes.Failure})); 
    }
}

export function* deletePayroll(action: ActionType<typeof actions.deletePayroll>): any {
    yield put(userActions.setLastUserAction(USER_ACTIONS.PAYROLL_DELETING)); 
    const userRequest = yield call(services.api.payroll.deletePayroll, action.payload.payrollId, action.payload.reason);     
    if (userRequest.isSuccess) {
        yield put(userActions.setAppToast({message: 'Payroll Deleted', type: AppToastTypes.Success}));
        yield put(userActions.setLastUserAction(USER_ACTIONS.PAYROLL_DELETING_SUCCESS)); 
        const state = yield select();        
        yield put(actions.requestPayrollList(state.payroll.search.payrollListRequest)); 
        const request: models.AdjusterFeeRequest = {
            isPaid: false, 
            hasAdjusterAmt: true, 
        }          
        yield put(actions.requestAdjusterFees(request));  
    }
    else {        
        console.log(userRequest); 
        yield put(userActions.setLastUserAction(USER_ACTIONS.PAYROLL_DELETING_FAILURE)); 
        const errorMessage = helpers.getResponseErrors(userRequest.data); 
        yield put(userActions.setAppToast({message: errorMessage, type: AppToastTypes.Failure})); 
    }
}

export function* requestAdjusterFees(action: ActionType<typeof actions.requestAdjusterFees>): any {        
    const userReqeust = yield call(services.api.payroll.getAdjusterFees, action.payload.request); 
    if(userReqeust.isSuccess){
        yield put(actions.receiveAdjusterFees(userReqeust.data)); 
    }
}
export function* requestAdjusterFeesList(action: ActionType<typeof actions.requestAdjusterFeesList>): any {  
    const userReqeust = yield call(services.api.payroll.getAdjusterFeeList, action.payload.request); 
    if(userReqeust.isSuccess){
        yield put(actions.receiveAdjusterFeesList(userReqeust.data)); 
    }
}

export function* requestAdjusterFeesListCSV(action: ActionType<typeof actions.requestAdjusterFeesListCSV>): any {
    const userRequest = yield call(services.api.payroll.getAdjusterFeeListCSV, action.payload.request); 
    if (userRequest){        
        yield put(actions.receiveAdjusterFeesListCSV()); 
    }
}

export function* requestPayroll(action: ActionType<typeof actions.requestPayroll>): any {
    const userReqeust = yield call(services.api.payroll.getPayrollDetail, action.payload.payrollId); 
    if (userReqeust.isSuccess) {
        yield put(actions.receivePayroll(userReqeust.data)); 
    }
}

export function* requestPayrollList(action: ActionType<typeof actions.requestPayrollList>): any {
    const userReqeust = yield call(services.api.payroll.getPayrollList, action.payload.request); 
    if (userReqeust.isSuccess) {
        yield put(actions.receivePayrollList(userReqeust.data)); 
    }
}

export function* requestPayrollCSV(action: ActionType<typeof actions.requestPayrollCSV>): any {
    const userRequest = yield call(services.api.payroll.getPayrollCSV, action.payload.request); 
    if (userRequest){        
        yield put(actions.receivePayrollCSV()); 
    }
}

export function* requestPayrollZipFile(action: ActionType<typeof actions.requestPayrollZipFile>): any {
    yield put(userActions.setLastUserAction(USER_ACTIONS.PAYROLL_GETTING_ZIP)); 
    const userRequest = yield call(services.api.payroll.getPayrollZipFile, action.payload.payrollId);     
    if (userRequest){         
        yield put(actions.receivePayrollZipFile());
        yield put(userActions.setLastUserAction(USER_ACTIONS.PAYROLL_GETTING_ZIP_SUCCESS)); 
    }
    else {
        yield put(userActions.setLastUserAction(USER_ACTIONS.PAYROLL_GETTING_ZIP_FAILURE)); 
        yield put(userActions.setAppToast({message: 'Error Retriving ZIP', type: AppToastTypes.Failure})); 
        yield put(actions.receivePayrollZipFile());
    }
}
