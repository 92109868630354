import * as models from '..'; 

export interface PayrollListRequest extends models.RequestBase {
    insClaimNo: string; 
    insCompany: string; 
    fieldAdjuster: string; 
    insuredName: string; 
    runDateFrom: string; 
    runDateTo: string; 
    paymentDateFrom: string; 
    paymentDateTo: string; 
    paymentInfo: string; 
    sort: string;
}

export const defaultPayrollListRequest: PayrollListRequest = {
    pageNo: 1, 
    pageSize: 20, 
    insClaimNo: '', 
    insCompany: '',
    fieldAdjuster: '',
    insuredName: '',
    runDateFrom: '',
    runDateTo: '',
    paymentDateFrom: '',
    paymentDateTo: '',
    paymentInfo: '',
    sort: '-runDate',
}