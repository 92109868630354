import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Card, Spinner, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'; 
import * as models from '../../models';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';
import SingleActionButton from 'components/common/SingleActionButton'; 
import Pager from 'components/common/Pager';
import downloadIcon from '../../assets/images/Blue/download.svg';
import { payrollActions } from 'store/payroll';
import infoIcon from '../../assets/images/Blue/info-circle.svg'; 

interface Props extends PropsFromRedux {
}

interface State {    
}

class ReportingTable extends React.Component<Props, State> {
    public state = {        
    };    

    public handleExport = async () => {   
        const exportQuery: models.AdjusterFeeListRequest = {
            ...this.props.adjusterFeeListRequest,
            pageNo: 1,
            pageSize: this.props.adjusterFeeListResponse.totalCount,
        }
        this.props.requestAdjusterFeesListCSV(exportQuery); 
    }

    public pagerCallback = (paging: models.Paging) => {   
        const request: models.AdjusterFeeListRequest = {
            ...this.props.adjusterFeeListRequest,
            ...paging,
        }
        this.props.requestAdjusterFeesList(request); 
    }; 

    public render() {
        const { adjusterFeeListResponse, adjusterFeeListRequest, isAdjusterFeeListLoading, isAdjusterFeeListDownloading } = this.props;        
        const showPagination =  adjusterFeeListResponse?.totalCount > adjusterFeeListRequest.pageSize ? true : false; 

        return (
            <Card className="claim-card">                
                {isAdjusterFeeListDownloading && 
                    <span>Downloading...</span> 
                }
                <Card.Title>Adjuster Fees
                    <OverlayTrigger
                            key="overlayTrigger"
                            placement="top"                                                            
                            overlay={
                                <Tooltip className="my-tooltip2" id="af-tooltip">
                                    Grain of this view is by Fee and Adjuster.<br/>
                                    <span style={{fontWeight: "bolder"}}>Fee Amt: </span>Qty * Rate, total amount of Fee.<br/>
                                    <span style={{fontWeight: "bolder"}}>Fees Paid: </span>Total amt remitted by carrier for Fee.<br/>
                                    <span style={{fontWeight: "bolder"}}>Last Pymt Dt: </span>most recent reconcile date.<br/>
                                    <span style={{fontWeight: "bolder"}}>Outstanding: </span>Fee Total - Fees Paid (remaining carrier balance)<br/>
                                    <span style={{fontWeight: "bolder"}}>Adj Total: </span>Fee Amt * Adjuster % (how much belongs to adjuster, regardless of collection or payroll)<br/>
                                    <span style={{fontWeight: "bolder"}}>Tot Adj Eligible: </span>Amt of adjuster total we've received.  Does not take into account Payrolled Amt.<br/>
                                    <span style={{fontWeight: "bolder"}}>Adj Balance: </span>Total Eligible Amt - Total Payrolled<br/>
                                    <span style={{fontWeight: "bolder"}}>Advanced Amt: </span>Total payed to adjuster prior to collection from carrier.<br/>
                                    <span style={{fontWeight: "bolder"}}>Total Payrolled: </span>Total amt payed out to adjuster.<br/>
                                </Tooltip>
                            }
                        >
                            <img className="info-icon" src={infoIcon}/>
                        </OverlayTrigger>
                    {isAdjusterFeeListLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}                    
                    <SingleActionButton
                            action="Export"
                            title="Payrolls"
                            img={downloadIcon}
                            onClick={this.handleExport}
                            disabled={isAdjusterFeeListDownloading}
                        />
                </Card.Title>  
                {isAdjusterFeeListLoading
                    && <span>Loading...</span> 
                }   
                {adjusterFeeListResponse?.totalCount == 0 && !isAdjusterFeeListLoading &&
                <div>0 results found</div>
                }
            {adjusterFeeListResponse.resourceList[0] &&
                <Table striped>
                    <thead>
                        <tr>
                            <th>Adjuster</th>
                            <th>Company</th>                            
                            <th>Claim #</th>
                            <th>Invoice #</th>
                            <th>Invoice Dt</th>
                            <th>Fee Name</th>
                            <th>Fee Amt</th>
                            <th>Fees Paid</th>
                            <th>Last Pymt Dt</th>
                            <th>Outstanding</th>
                            <th>Adjuster %</th>                            
                            <th>Adj Total</th>
                            <th>Tot Adj Eligible</th>
                            <th>Adj Balance</th>
                            <th>Advanced Amt</th>
                            <th>Total Payrolled</th>
                        </tr>
                    </thead>
                    <tbody>
                    {adjusterFeeListResponse.resourceList.map((x: models.AdjusterFee, i: number) => (
                        <tr key={i}>
                            <td>{x.adjuster}</td>
                            <td>{x.insCompanyName}</td>                            
                            <td>{x.insClaimNumber}</td>
                            <td>{x.invoiceNumber}</td>
                            <td>{dateFormat(x.invoiceDt, 'mm/dd/yyyy')}</td>
                            <td>{x.feeName}</td>
                            <td><NumberFormat value={x.feeAmt?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                            <td><NumberFormat value={x.feesPaid?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                            <td>{dateFormat(x.lastPaymentDate, 'mm/dd/yyyy')}</td>
                            <td><NumberFormat value={x.outstandingBalance?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            <td><NumberFormat value={x.adjusterPercent} displayType={'text'}  suffix={'%'} /></td>                            
                            <td><NumberFormat value={x.adjusterTotal?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            <td><NumberFormat value={x.adjusterEligibleAmt?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            <td><NumberFormat value={x.adjusterBalance?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            <td><NumberFormat value={x.advancePayrolled?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                            <td><NumberFormat value={x.totalPayrolled?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>                            
                        </tr>
                    ))}
                    </tbody>
                </Table>
            }
            {showPagination &&
                <Pager
                    className = {"admin-pager"}
                    retrieveDataCallback = {this.pagerCallback}
                    pageSize = {adjusterFeeListResponse.resourceList?.length}
                    currentPage = {adjusterFeeListRequest.pageNo}
                    totalCount = {adjusterFeeListResponse.totalCount}
                    pagesInRange = {3}
                />
            }
            </Card>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    adjusterFeeListResponse: state.payroll.search.adjusterFeeListResponse,
    adjusterFeeListRequest: state.payroll.search.adjusterFeeListRequest, 
    isAdjusterFeeListLoading: state.payroll.search.isAdjusterFeeListLoading, 
    isAdjusterFeeListDownloading: state.payroll.search.isAdjusterFeeListDownloading, 
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            requestAdjusterFeesList: payrollActions.requestAdjusterFeesList, 
            requestAdjusterFeesListCSV: payrollActions.requestAdjusterFeesListCSV,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ReportingTable);