import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';

export const requestMonthlyClaimActivity = (monthsBack: number, months: number) =>
    action(constants.REQUEST_MONTHLY_CLAIM_ACTIVITY, { monthsBack, months }); 

export const receiveMonthlyClaimActivity = (response: models.MonthlyClaimActivityResponse) => 
    action(constants.RECEIVE_MONTHLY_CLAIM_ACTIVITY, { response }); 

export const requestPayrollDashboard = () => 
    action(constants.REQUEST_PAYROLL_DASHBOARD); 
export const receivePayrollDashboard = (response: models.PayrollDashboard) => 
    action(constants.RECEIVE_PAYROLL_DASHBOARD, { response }); 